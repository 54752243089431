import React, { ChangeEvent } from "react";
import Close from "@material-ui/icons/Close";

interface Props {
  searchInput: string;
  setSearchInput: (search: string) => void;
  placeholder?: string;
  EndIcon?: any;
  width?: number;
  isStatic?: boolean;
}

const SearchBar: React.FC<Props> = ({
  searchInput,
  setSearchInput,
  placeholder,
  EndIcon,
  width,
  isStatic,
}) => {
  const [inputWidth, setInputWidth] = React.useState(width || 300);
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchInput(e.target.value);
  };

  const clearSearch = () => {
    setSearchInput("");
  };

  const onInputFocus = (focus: boolean) => {
    if (isStatic) return;
    if (focus) setInputWidth(350);
    else setInputWidth(300);
  };

  return (
    <div className="search-bar" style={{ width: inputWidth }}>
      <div className="space-between row padding row-input">
        <input
          className="input"
          placeholder={placeholder || "Search"}
          value={searchInput}
          type="text"
          style={{fontSize:"1.1rem"}}
          onChange={handleChange}
          onFocus={() => onInputFocus(true)}
          onBlur={() => onInputFocus(false)}
        />
        {!EndIcon ? (
          searchInput ? (
            <Close
              style={{
                color: "#FFC000",
                cursor: "pointer",
                marginTop: ".7rem",
                transform:"translateY(-0.3rem)"
              }}
              onClick={clearSearch}
            />
          ) : (
            <img
              height="20rem"
              src="/assets/images/searchIcon.svg"
              alt="search"
            />
          )
        ) : (
          <EndIcon />
        )}
      </div>
    </div>
  );
};

export default SearchBar;
