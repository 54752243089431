import React, { useState } from "react";
import { toast } from "react-toastify";
import api from "../../network/api";
import EditIcon from "../SVGIcons/EditIcon";
import SaveIcon from "../SVGIcons/SaveIcon";
import TextareaAutosize from "react-textarea-autosize";
import CircularLoading from "../Animations/Loading";
import { useSelector } from "react-redux";
import { RootState } from "../../store/root";

export default function TemplatesTable() {
  const [templates, setTemplates] = useState([]);
  const [editingTemplateName, setEditingTemplateName] = useState(-1);
  const [editingTemplate, setEditingTemplate] = useState(-1);
  const [loading, setLoading] = useState(true);

  const auth = useSelector((state: RootState) => state.auth);

  const updateTemplate = async (
    id: number,
    templateName: string,
    template: string
  ) => {
    try {
      const res = await api.post(
        "/templates",
        {
          templateName:
            window.sessionStorage.getItem(`templateName${id}`) || templateName,
          template: window.sessionStorage.getItem(`template${id}`) || template,
          id: id,
        },
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`,
          },
        }
      );
      toast.success(
        res.status === 202 ? "Template already exists" : "Template updated"
      );
      setEditingTemplate(-1);
      setEditingTemplateName(-1);
    } catch (error) {
      toast.error("Could not update template");
    }
  };

  React.useEffect(() => {
    const getTemplates = async () => {
      setLoading(true);
      try {
        const { data: templates } = await api.get("/templates", {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`,
          },
        });

        setTemplates(templates);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };

    getTemplates();
  }, []);

  return (
    <div
      style={{
        marginTop: "200px",
        gridTemplateColumns: "auto auto",
        columnGap: "20px",
        marginInline: "15vw",
        rowGap: "20px",
        display: "grid",
      }}
    >
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "50vw",
            height: "50vh",
          }}
        >
          <CircularLoading />
        </div>
      ) : (
        <>
          {templates.map((template, index) => (
            <div key={index}>
              <div
                style={{
                  width: "auto",
                  color: "black",
                  backgroundColor: "#ffcc00",
                  paddingInline: "20px",
                  display: "flex",
                  justifyContent: "space-between",
                  fontWeight: "bold",
                  alignItems: "center",
                  paddingBlock: "10px",
                  borderRadius: "10px",
                  cursor: "pointer",
                }}
              >
                <input
                  type="text"
                  onChange={(e) =>
                    window.sessionStorage.setItem(
                      `templateName${template["id"]}`,
                      e.target.value
                    )
                  }
                  disabled={editingTemplateName === index ? false : true}
                  style={{
                    fontWeight: "bold",
                    background: "transparent",
                    padding: "10px",
                    borderRadius: "10px",
                    border: editingTemplateName === index ? "" : "none",
                  }}
                  defaultValue={template["templateName"]}
                />

                {auth.authenticated && auth.user!.isAdmin && (
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <div
                      onClick={() => {
                        editingTemplateName === index
                          ? setEditingTemplateName(-1)
                          : setEditingTemplateName(index);
                      }}
                      style={{
                        height: "20px",
                        marginLeft: "10px",
                        width: "20px",
                        display:
                          editingTemplateName === index ? "none" : "block",
                      }}
                    >
                      <EditIcon />
                    </div>

                    <div
                      onClick={() => {
                        setEditingTemplateName(-1);
                      }}
                      style={{
                        height: "20px",
                        marginLeft: "10px",
                        width: "20px",
                        color: "black",
                        fontWeight: "bold",
                        fontSize: "1.5rem",
                        transform: "translateY(-6px)",
                        display:
                          editingTemplateName === index ? "block" : "none",
                      }}
                    >
                      &times;
                    </div>

                    <div
                      onClick={() => {
                        updateTemplate(
                          template["id"],
                          template["templateName"],
                          template["template"]
                        );
                      }}
                      style={{
                        height: "20px",
                        marginLeft: "10px",
                        width: "20px",
                        color: "black",
                        display:
                          editingTemplateName === index ? "block" : "none",
                      }}
                    >
                      <SaveIcon color={"#000"} />
                    </div>
                  </div>
                )}
              </div>
              <div
                style={{
                  display: "flex",
                  color: "black",
                  fontWeight: "bold",
                  paddingBlock: "10px",
                  borderRadius: "10px",
                  paddingInline: "20px",
                  marginTop: "5px",
                  justifyContent: "space-between",
                  backgroundColor: "white",
                }}
              >
                <TextareaAutosize
                  onChange={(e) =>
                    window.sessionStorage.setItem(
                      `template${template["id"]}`,
                      e.target.value
                    )
                  }
                  disabled={editingTemplate === index ? false : true}
                  style={{
                    fontWeight: "bold",
                    background: "transparent",
                    width: "100%",
                    padding: "10px",
                    borderRadius: "10px",
                    border: editingTemplate === index ? "" : "none",
                  }}
                  defaultValue={template["template"]}
                />

                <div
                  style={{
                    display: "flex",
                    cursor: "pointer",
                    flexDirection: "column",
                    marginLeft: "20px",
                  }}
                >
                  {auth.authenticated && auth.user!.isAdmin && (
                    <div
                      onClick={() => {
                        editingTemplate === index
                          ? setEditingTemplate(-1)
                          : setEditingTemplate(index);
                      }}
                      style={{
                        height: "20px",
                        marginLeft: "10px",
                        width: "20px",
                        display: editingTemplate === index ? "none" : "block",
                      }}
                    >
                      <EditIcon />
                    </div>
                  )}
                  <div
                    onClick={() => {
                      setEditingTemplate(-1);
                    }}
                    style={{
                      height: "20px",
                      marginLeft: "10px",
                      width: "20px",
                      color: "black",
                      fontWeight: "bold",
                      fontSize: "1.5rem",
                      transform: "translate(5px,-6px)",
                      display: editingTemplate === index ? "block" : "none",
                    }}
                  >
                    &times;
                  </div>

                  <div
                    onClick={() => {
                      updateTemplate(
                        template["id"],
                        template["templateName"],
                        template["template"]
                      );
                    }}
                    style={{
                      height: "20px",
                      marginLeft: "10px",
                      marginTop: "20px",
                      width: "20px",
                      color: "black",
                      display: editingTemplate === index ? "block" : "none",
                    }}
                  >
                    <SaveIcon color={"#000"} />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </>
      )}
    </div>
  );
}
