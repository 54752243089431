import { createStore, applyMiddleware } from 'redux';
import logger from 'redux-logger';
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import rootReducer from './root';

const middlewares: any[] = [thunk];

if (process.env.NODE_ENV === 'development') middlewares.push(logger);
const persistConfig = {
	key: 'lwm-root',
	storage,
};
const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = createStore(persistedReducer, applyMiddleware(...middlewares));

export const persistor = persistStore(store);
export default store;
