import React from 'react';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Sync from '@material-ui/icons/Sync';
import { useHistory } from 'react-router-dom';
import '../css/create.css';
import { useSelector } from 'react-redux';
import { RootState } from '../store/root';
import SideBar from '../components/sidebar';
import { Button, FormControlLabel, Checkbox } from '@material-ui/core';
import Add from '@material-ui/icons/Add';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import api from '../network/api';
import { toast } from 'react-toastify';
import { BeatLoader } from 'react-spinners';
import { format } from 'date-fns';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        syncIcon: {
            height: 20,
            width: 20,
        },
        typography: {
            fontWeight: 'bold',
            color: '#eee',
            justifyContent: 'flex-end',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
        },
        typographyLight: {
            color: 'grey',
            fontWeight: 'bold',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
        },
        tablePadding: {
            paddingLeft: '100px',
            paddingRight: '100px',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
        },
        bold: {
            fontWeight: 'bold',
        },
        root: {
            width: '100%',
            backgroundColor: '#000',
            color: '#fff',
        },
        chip: {
            borderRadius: '5px',
            fontWeight: 'bold',
            marginLeft: '12px',
            marginBottom: '10px',
            height: '20px',
            color: 'green',
            backgroundColor: '#90ee90',
        },
        section1: {
            margin: theme.spacing(3, 2),
        },
        section2: {
            margin: theme.spacing(2),
        },
        section3: {
            margin: theme.spacing(3, 1, 1),
        },
    })
);

const Create: React.FC = () => {
    const classes = useStyles();
    const history = useHistory();
    const auth = useSelector((state: RootState) => state.auth);
    const [loading, setLoading] = React.useState(false);

    const { values, handleChange, handleSubmit, setFieldValue, errors } = useFormik<{
        name: string;
        email: string;
        childName: string;
        childBirthday: Date;
        customerId: string;
        couponApplied: string;
        instagramAccount: string;
        phone: string;
        shippingAddress: string;
        influencer: boolean;
        subscriptionType: string;
        subscriptionStart?: Date;
    }>({
        initialValues: {
            name: '',
            email: '',
            childName: '',
            childBirthday: new Date(),
            customerId: '',
            couponApplied: '',
            instagramAccount: '',
            phone: '',
            shippingAddress: '',
            influencer: false,
            subscriptionType: '',
        },
        onSubmit: async (values) => {
            try {
                setLoading(true);
                const data: any = {
                    ...values,
                    childBirthday: format(values.childBirthday, 'yyyy-MM-dd'),
                };
                if (values.subscriptionStart) {
                    data['subscriptionStart'] = format(values.subscriptionStart, 'yyyy-MM-dd');
                }
                await api.post(`/customers/new`, data, {
                    headers: {
                      Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`,
                    },
                  });
                toast.success('Customer added successfully');
                setLoading(false);
                history.push('/');
            } catch (error) {
                toast.error('Unable to add customer');
                setLoading(false);
            }
        },
        validationSchema: Yup.object({
            name: Yup.string().required(),
            email: Yup.string().email().required(),
            childName: Yup.string().required(),
            childBirthday: Yup.string().required(),
            customerId: Yup.string().optional(),
            couponApplied: Yup.string().optional(),
            instagramAccount: Yup.string().optional(),
            phone: Yup.string().required(),
            shippingAddress: Yup.string().required(),
        }),
    });

    React.useEffect(() => {
        if (!auth.authenticated) {
            history.replace('/login');
        }
    }, [auth, history]);

    return (
        <div className="container">
            <aside className="sidebar">
                <SideBar />
            </aside>
            <main className="main">
                <div className={classes.root}>
                    <div className={classes.section1}>
                        <Grid container>
                            <div className="row">
                                <Grid item xs>
                                    <div className="row">
                                        <div className="small-container">
                                            <Sync
                                                style={{
                                                    color: 'white',
                                                    width: '18px',
                                                    height: '20px',
                                                    paddingLeft: '2px',
                                                }}
                                            />
                                        </div>
                                        <div style={{ marginLeft: '12px' }}>
                                            <Typography
                                                className={classes.typography}
                                                gutterBottom
                                                variant="body1"
                                            >
                                                SUBSCRIPTION
                                            </Typography>
                                        </div>
                                    </div>
                                    <div className="row center">
                                        <input
                                            id="name"
                                            name="name"
                                            style={{ fontSize: '35px' }}
                                            placeholder="Customer Name"
                                            className={`create-input ${
                                                errors.name ? 'error-input' : ''
                                            }`}
                                            value={values.name}
                                            onChange={handleChange}
                                            type="text"
                                            disabled={loading}
                                        />
                                    </div>
                                </Grid>
                                <div className="float-right" style={{ alignItems: 'flex-end' }}>
                                    <div className="column">
                                        <Button
                                            disableElevation={true}
                                            variant="contained"
                                            endIcon={<Add />}
                                            style={{
                                                marginLeft: '25px',
                                                backgroundColor: '#ffc000',
                                                color: '#000',
                                                fontWeight: 'bold',
                                                borderRadius: '8px',
                                            }}
                                            onClick={() => handleSubmit()}
                                            disabled={loading}
                                        >
                                            {loading ? <BeatLoader /> : 'Create Customer'}
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </Grid>
                    </div>
                    <Divider variant="middle" />

                    <br />
                    <div className="padding">
                        <Typography className={classes.bold} gutterBottom variant="h6">
                            Customer Details
                        </Typography>
                    </div>
                    <Divider variant="middle" />
                    <br />
                    <div className="column padding">
                        <div className="row">
                            <table>
                                <tr>
                                    <th>
                                        <Typography
                                            className={classes.typographyLight}
                                            gutterBottom
                                            variant="body1"
                                        >
                                            Email
                                        </Typography>
                                    </th>
                                    <th className={classes.tablePadding}>
                                        <div className="row">
                                            <input
                                                id="email"
                                                name="email"
                                                placeholder="Email"
                                                className={`create-input ${
                                                    errors.email ? 'error-input' : ''
                                                }`}
                                                value={values.email}
                                                onChange={handleChange}
                                                type="email"
                                                disabled={loading}
                                            />
                                        </div>
                                    </th>
                                </tr>
                                <tr>
                                    <th>
                                        <Typography
                                            className={classes.typographyLight}
                                            gutterBottom
                                            variant="body1"
                                        >
                                            Child
                                        </Typography>
                                    </th>
                                    <th className={classes.tablePadding}>
                                        <div className="row">
                                            <input
                                                id="childName"
                                                name="childName"
                                                placeholder="Child's Name"
                                                className={`create-input ${
                                                    errors.childName ? 'error-input' : ''
                                                }`}
                                                value={values.childName}
                                                onChange={handleChange}
                                                type="text"
                                                disabled={loading}
                                            />
                                        </div>
                                    </th>
                                </tr>
                                <tr>
                                    <th>
                                        <Typography
                                            className={classes.typographyLight}
                                            gutterBottom
                                            variant="body1"
                                        >
                                            Stripe customer ID
                                        </Typography>
                                    </th>
                                    <th className={classes.tablePadding}>
                                        <div className="row">
                                            <input
                                                id="customerId"
                                                name="customerId"
                                                placeholder="Customer ID"
                                                className={`create-input ${
                                                    errors.customerId ? 'error-input' : ''
                                                }`}
                                                value={values.customerId}
                                                onChange={handleChange}
                                                type="text"
                                                disabled={loading}
                                            />
                                        </div>
                                    </th>
                                </tr>
                                <tr>
                                    <th>
                                        <Typography
                                            className={classes.typographyLight}
                                            gutterBottom
                                            variant="body1"
                                        >
                                            Discount Code
                                        </Typography>
                                    </th>
                                    <th className={classes.tablePadding}>
                                        <div className="row">
                                            <input
                                                id="couponApplied"
                                                name="couponApplied"
                                                placeholder="Discount Code"
                                                className={`create-input ${
                                                    errors.couponApplied ? 'error-input' : ''
                                                }`}
                                                value={values.couponApplied}
                                                onChange={handleChange}
                                                type="text"
                                                disabled={loading}
                                            />
                                        </div>
                                    </th>
                                </tr>
                                <tr>
                                    <th>
                                        <Typography
                                            className={classes.typographyLight}
                                            gutterBottom
                                            variant="body1"
                                        >
                                            Instagram Handle
                                        </Typography>
                                    </th>
                                    <th className={classes.tablePadding}>
                                        <div className="row">
                                            <input
                                                id="instagramAccount"
                                                name="instagramAccount"
                                                value={values.instagramAccount}
                                                onChange={handleChange}
                                                placeholder="Instagram Handle"
                                                className={`create-input ${
                                                    errors.instagramAccount ? 'error-input' : ''
                                                }`}
                                                type="text"
                                                disabled={loading}
                                            />
                                        </div>
                                    </th>
                                </tr>
                                <tr>
                                    <th>
                                        <Typography
                                            className={classes.typographyLight}
                                            gutterBottom
                                            variant="body1"
                                        >
                                            Influencer
                                        </Typography>
                                    </th>
                                    <th className={classes.tablePadding}>
                                        <div className="row">
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={values.influencer}
                                                        onChange={(_, checked) =>
                                                            setFieldValue('influencer', checked)
                                                        }
                                                        style={{ background: '#fff' }}
                                                    />
                                                }
                                                label="Influencer?"
                                            />
                                        </div>
                                    </th>
                                </tr>
                            </table>
                            <table>
                                <tr>
                                    <th>
                                        <Typography
                                            className={classes.typographyLight}
                                            gutterBottom
                                            variant="body1"
                                        >
                                            Phone Number
                                        </Typography>
                                    </th>
                                    <th className={classes.tablePadding}>
                                        <div className="row">
                                            <input
                                                id="phone"
                                                name="phone"
                                                value={values.phone}
                                                onChange={handleChange}
                                                placeholder="Phone Number"
                                                className={`create-input ${
                                                    errors.phone ? 'error-input' : ''
                                                }`}
                                                type="text"
                                                disabled={loading}
                                            />
                                        </div>
                                    </th>
                                </tr>
                                <tr>
                                    <th>
                                        <Typography
                                            className={classes.typographyLight}
                                            gutterBottom
                                            variant="body1"
                                        >
                                            Shipping Address
                                        </Typography>
                                    </th>
                                    <th className={classes.tablePadding}>
                                        <div className="row">
                                            <textarea
                                                id="shippingAddress"
                                                name="shippingAddress"
                                                value={values.shippingAddress}
                                                onChange={handleChange}
                                                placeholder="Shipping Address"
                                                className={`create-input ${
                                                    errors.shippingAddress ? 'error-input' : ''
                                                }`}
                                                rows={4}
                                                disabled={loading}
                                            />
                                        </div>
                                    </th>
                                </tr>
                                <tr>
                                    <th>
                                        <Typography
                                            className={classes.typographyLight}
                                            gutterBottom
                                            variant="body1"
                                        >
                                            Child Birthday
                                        </Typography>
                                    </th>
                                    <th className={classes.tablePadding}>
                                        <div className="row">
                                            <KeyboardDatePicker
                                                value={values.childBirthday}
                                                onChange={(date) =>
                                                    setFieldValue('childBirthday', date)
                                                }
                                                format="dd/MM/yyyy"
                                                variant="inline"
                                                error={!!errors.childBirthday}
                                            />
                                        </div>
                                    </th>
                                </tr>
                                <tr>
                                    <th>
                                        <Typography
                                            className={classes.typographyLight}
                                            gutterBottom
                                            variant="body1"
                                        >
                                            Subscription Plan <br /> (month_1, month_9, month_12)
                                        </Typography>
                                    </th>
                                    <th className={classes.tablePadding}>
                                        <div className="row">
                                            <select
                                                id="customerId"
                                                name="subscriptionType"
                                                placeholder="Plan"
                                                className={`create-input ${
                                                    errors.subscriptionType ? 'error-input' : ''
                                                }`}
                                                value={values.subscriptionType}
                                                onChange={handleChange}
                                                disabled={loading}
                                            >
                                                <option value="month_1">Monthly</option>
                                                <option value="month_9">Every 9 months</option>
                                                <option value="month_12">Yearly</option>
                                            </select>
                                        </div>
                                    </th>
                                </tr>
                                <tr>
                                    <th>
                                        <Typography
                                            className={classes.typographyLight}
                                            gutterBottom
                                            variant="body1"
                                        >
                                            Subscription Start
                                        </Typography>
                                    </th>
                                    <th className={classes.tablePadding}>
                                        <div className="row">
                                            <KeyboardDatePicker
                                                value={values.subscriptionStart}
                                                onChange={(date) =>
                                                    setFieldValue('subscriptionStart', date)
                                                }
                                                format="dd/MM/yyyy"
                                                variant="inline"
                                                error={!!errors.subscriptionStart}
                                            />
                                        </div>
                                    </th>
                                </tr>
                            </table>
                        </div>
                    </div>

                    <br />
                    <Divider variant="middle" />
                    <br />
                </div>

                <br />
            </main>
        </div>
    );
};

export default Create;
