/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
import { Influencer } from "../../@types";
import api from "../../network/api";
import { makeStyles } from "@material-ui/core";
import ReactSelect from "react-select";
import AscendingIcon from "../SVGIcons/ascendingIcon";
import DescendingIcon from "../SVGIcons/descendingIcon";
import OrderedTable from "../OrderedTable";
const useStyles = makeStyles((theme) => ({
  customers: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: "1rem",
    paddingRight: "2rem",
  },
  select: {
    width: "12rem",
    color: "#000",
    borderRadius: "1.2rem",
  },
}));

type Props = {
  influencers: Influencer[];
  originalInfluencers: Influencer[];
  loading: boolean;
};

function Ordered({ influencers, originalInfluencers, loading }: Props) {
  const [order, setOrder] = React.useState(true);
  const [orderBy, setOrderBy] = React.useState("created_at");
  const classes = useStyles();

  const confirmedOptions = [
    { value: "created_at", label: "Date" },
    { value: "email", label: "Email" },
    // { value: "name", label: "Name" },
    { value: "followers", label: "Followers" },
    { value: "rate", label: "Engagement" },
    { value: "username", label: "Username" },
    // { value: "id", label: "Id" },
  ];

  // const handleSearch = (query: string) => {
  //   const results = originalConfirmed.filter(
  //     (profile) =>
  //       profile.first_name?.toLowerCase().includes(query.toLowerCase()) ||
  //       profile.last_name?.toLowerCase().includes(query.toLowerCase()) ||
  //       profile.email?.toLowerCase().includes(query.toLowerCase())
  //   );
  //   setConfirmed(results);
  // };

  function descendingComparator<InstagramProfile>(
    a: InstagramProfile,
    b: InstagramProfile,
    orderBy: keyof InstagramProfile
  ) {
    var cA = (a[orderBy] as unknown as string) || "";
    var cB = (b[orderBy] as unknown as string) || "";
    if (orderBy === "rate" || orderBy === "followers") {
      if (parseInt(cA) < parseInt(cB)) {
        return -1;
      }
      if (parseInt(cA) > parseInt(cB)) {
        return 1;
      }
      return 0;
    } else {
      if (cA.toLowerCase() < cB.toLowerCase()) {
        return -1;
      }
      if (cA.toLowerCase() > cB.toLowerCase()) {
        return 1;
      }
      return 0;
    }
  }



  function stableSort<InstagramProfile>(
    array: any,
    orderBy: string,
    comparator: (a: InstagramProfile, b: InstagramProfile) => number
  ) {
    const stabilizedThis = array.map(
      (el: any, index: any) => [el, index] as [InstagramProfile, number]
    );
    stabilizedThis.sort((a: any, b: any) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el: any) => el[0]);
  }

  function getComparator<Key extends keyof any>(
    order: string,
    orderBy: Key
  ): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string }
  ) => number {
    return order === "desc"
      ? (a, b) => -descendingComparator(a, b, orderBy)
      : (a, b) => descendingComparator(a, b, orderBy);
  }



  return (
    <div>
      <div
        style={{
          alignItems: "start",
          alignSelf: "flex-end",
          zIndex: 1,
          backgroundColor: "red",
          display: "flex",
          position: "sticky",
          top: "300",
          marginLeft: "2rem",
          justifyContent: "flex-end",
        }}
      >
        <div style={{ marginTop: "-8rem" }} className="search-bar">
          <div className="space-between row padding row-input">
            <input
              className="input"
              placeholder="Search"
              type="text"
              style={{ fontSize: "1.1rem" }}
              // onChange={(e) => handleSearch(e.target.value)}
            />
          </div>
        </div>
        <div
          style={{
            marginTop: "-8rem",
            marginLeft: "1rem",
            flexDirection: "row",
            display: "flex",
            alignItems: "center",
          }}
        >
          <ReactSelect
            className={classes.select}
            onChange={(e) => {
              setOrderBy(e?.value as string);
              const result = stableSort(
                influencers,
                e?.value as string,
                getComparator(order ? "asc" : "desc", e?.value as string)
              );
              // setConfirmed(result);
            }}
            placeholder="Filters"
            options={confirmedOptions}
            isClearable
          />
          <div
            onClick={() => {
              const result = stableSort(
                influencers,
                orderBy,
                getComparator(!order ? "asc" : "desc", orderBy)
              );
              // setConfirmed(result);
              setOrder(!order);
            }}
            style={{
              height: "30px",
              width: "30px",
              marginLeft: "20px",
              marginTop: "3px",
              cursor: "pointer",
            }}
          >
            {order ? <AscendingIcon /> : <DescendingIcon />}
          </div>
        </div>
      </div>
      <OrderedTable influencers={influencers} loading={loading} />
    </div>
  );
}

export default Ordered;
