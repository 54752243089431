import { RouteConfig } from 'react-router-config';
import Dashboard from './pages/dashboard';
import SignIn from './pages/login';
import Details from './pages/details';
import Create from './pages/create';
import Edit from './pages/edit';
import IgDetails from './pages/IgDetails';

const routes: RouteConfig[] = [
    { path: '/', component: Dashboard, exact: true },
    { path: '/login', component: SignIn, exact: true },
    { path: '/create', component: Create, exact: true },
    { path: '/:id/edit', component: Edit, exact: true },
    { path: '/customers/:id', component: Details, exact: true },
    { path: '/influencer/:email', component: IgDetails, exact: true },
];

export default routes;
