import { AnyAction } from 'redux';

export interface MiscState {
    activeSidebar: string;
    activePage: number;
}

export const MISC_ACTIONS = {
    SET_ACTIVE_SIDE_BAR: 'SET_ACTIVE_SIDE_BAR',
    SET_ACTIVE_PAGE: 'SET_ACTIVE_PAGE',
};

export const initialState: MiscState = {
    activeSidebar: 'customers',
    activePage: 1,
};

export const setActiveSidebar = (activeSidebar: string) => {
    return {
        type: MISC_ACTIONS.SET_ACTIVE_SIDE_BAR,
        payload: activeSidebar,
    };
};

export const setActivePageNumber = (activePage: number) => {
    return {
        type: MISC_ACTIONS.SET_ACTIVE_PAGE,
        payload: activePage,
    };
};

export default function miscReducer(state = initialState, action: AnyAction): MiscState {
    switch (action.type) {
        case MISC_ACTIONS.SET_ACTIVE_SIDE_BAR:
            return { ...state, activeSidebar: action.payload, activePage: 1 };
        case MISC_ACTIONS.SET_ACTIVE_PAGE:
            return { ...state, activePage: action.payload };
        default:
            return state;
    }
}
