import React, { useState } from "react";
import logo from "../assets/images/mochi-logo.png";
import { Image } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import K from "../constants";
import { logoutUser } from "../store/modules/auth.reducer";
import { capitalCase } from "change-case";
//import react pro sidebar components
import {
  ProSidebar,
  Menu,
  MenuItem,
  SidebarHeader,
  SidebarFooter,
  SidebarContent,
} from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import "../css/sidebar.css";
import { RootState } from "../store/root";
import { setActiveSidebar } from "../store/modules/misc.reducer";
import { toast } from "react-toastify";
import customers from "../assets/images/customers.svg";
import lookup from "../assets/images/lookup.svg";
import influencers from "../assets/images/influencers.svg";
import confirmed from "../assets/images/confirmed.svg";
import templates from "../assets/images/templates.svg";
import MenuIcon from "./SVGIcons/MenuIcon";
import LogoutIcon from "./SVGIcons/LogoutIcon";

const SideBar: React.FC = () => {
  const misc = useSelector((state: RootState) => state.misc);
  const history = useHistory();
  const [menuCollapse, setMenuCollapse] = useState(false);
  const dispatch = useDispatch();

  const sidebarItems: string[] = [
    "customers",
    "abandoned",
    "influencers",
    // "emails",
    // "lookup",
    // "confirmed",
    "templates",
  ];

  const logout = () => {
    window.localStorage.removeItem(K.LWM_ADMIN_TOKEN_KEY);
    dispatch(logoutUser());
    toast.success("Logged out successfully");
  };

  const setSidebar = (sidebar: string) => {
    dispatch(setActiveSidebar(sidebar));
  };
  const renderIcon = (sidebar: string) => {
    switch (sidebar) {
      case "customers":
        return customers;
      case "abandoned":
        return customers;
      case "confirmed":
        return confirmed;
      case "lookup":
        return lookup;
      case "influencers":
        return influencers;
      default:
        return templates;
    }
  };

  return (
    <>
      <div id="header">
        <ProSidebar collapsed={menuCollapse}>
          <SidebarHeader style={{backgroundColor:"white"}} >
            <div className="padding">
              {menuCollapse ? (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  {" "}
                  <Image className="small-logo" src={logo} />
                </div>
              ) : (
                <Image className="logo" src={logo} />
              )}
            </div>
          </SidebarHeader>
          <SidebarContent style={{backgroundColor:"white"}}  >
            <Menu>
              {sidebarItems.map((item) => (
                <MenuItem
                  // onMouseOver={()=>toast.success('over')}
                  // onMouseLeave={()=>toast.error('Leave')}

                  key={item}
                  className={
                    misc.activeSidebar === item
                      ? "active sidebar-item"
                      : "inactive sidebar-item"
                  }
                  style={{
                    opacity:
                      misc.activeSidebar === "customers"
                        ? "1"
                        : item === "abandoned" &&
                          misc.activeSidebar !== "abandoned"
                        ? "0"
                        : "1",
                    height:
                      misc.activeSidebar === "customers"
                        ? "60px"
                        : item === "abandoned" &&
                          misc.activeSidebar !== "abandoned"
                        ? "0px"
                        : "60px",
                    transition: "ease 0.1s all",
                  }}
                  active={misc.activeSidebar === item}
                  onClick={() => {
                    setSidebar(item);
                    if (history.location.pathname !== "/") {
                      history.push("/");
                    }
                  }}
                >
                  <img src={renderIcon(item)} alt="item" className="item-img" />
                  <span
                    style={{
                      opacity: menuCollapse ? "0" : "1",
                      transition: "all 0.2s ease-out",
                    }}
                  >
                    {capitalCase(item)}
                  </span>
                </MenuItem>
              ))}
            </Menu>
          </SidebarContent>

          <SidebarFooter style={{backgroundColor:"white"}} >
            <div
              style={{ display: "flex", justifyContent: "center" }}
              // className="closemenu"
              onClick={() => setMenuCollapse(!menuCollapse)}
            >
              {menuCollapse ? (
                <div
                  style={{ cursor: "pointer", height: "30px", width: "30px" }}
                >
                  <MenuIcon />
                </div>
              ) : (
                <div
                  style={{ cursor: "pointer", height: "30px", width: "30px" }}
                >
                  <MenuIcon />
                </div>
              )}
            </div>
            <Menu iconShape="square" onClick={logout}>
              <MenuItem>
                <p
                  style={{
                    color: "#000",
                    background: "#ffcc00",
                    padding: ".6rem",
                    width: "100%",
                    borderRadius: "4px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginRight: "1rem",
                  }}
                >
                  {menuCollapse ? (
                    <div style={{ height: "20px", width: "20px" }}>
                      <LogoutIcon />
                    </div>
                  ) : (
                    "Logout"
                  )}
                </p>
              </MenuItem>
            </Menu>
          </SidebarFooter>
        </ProSidebar>
      </div>
    </>
  );
};

export default SideBar;
