import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { renderRoutes } from 'react-router-config';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ToastContainer } from 'react-toastify';
import { HelmetProvider } from 'react-helmet-async';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import './App.css';
import routes from './router';
import 'react-toastify/dist/ReactToastify.min.css';
import K from './constants';
import setAuthToken from './network/set-auth-token';

const App: React.FC = () => {
    // check if token exists and save to auth token
    React.useEffect(() => {
        const token = window.localStorage.getItem(K.LWM_ADMIN_TOKEN_KEY);
        if (token) {
            setAuthToken(token);
        }
    }, []);

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <HelmetProvider>
                {/* css baseline */}
                <CssBaseline />

                <BrowserRouter>
                    {/* render application routes */}
                    {renderRoutes(routes)}
                </BrowserRouter>

                {/* toast container */}
                <ToastContainer />
            </HelmetProvider>
        </MuiPickersUtilsProvider>
    );
};

export default App;
