/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import NavigationBar from "../components/navigation-bar";
import SideBar from "../components/sidebar";
import { RootState } from "../store/root";
import { Customer, Influencer } from "../@types";
import api from "../network/api";
import { toast } from "react-toastify";
import K from "../constants";
import DashboardTable from "../components/dashboard-table";
import Lookup from "../components/dashboard/lookup";
import Confirmed from "../components/dashboard/confirmed";
import TemplatesTable from "../components/TemplatesTable";
import EmailHistoryTable from "../components/EmailHistoryTable";

// import Email from "../components/email";

const Dashboard: React.FC = () => {
  const { activeSidebar, activePage } = useSelector(
    (state: RootState) => state.misc
  );
  const auth = useSelector((state: RootState) => state.auth);
  const [customers, setCustomers] = React.useState<Customer[]>([]);
  const [influencers, setInfluencers] = React.useState<Influencer[]>([]);
  const [abandoned, setAbandoned] = React.useState<Customer[]>([]);
  const [searchData, setSearchData] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [searchInput, setSearchInput] = React.useState("");
  // let [menuCollapse] = React.useState(false)
  const history = useHistory();

  React.useEffect(() => {
    if (!auth.authenticated) {
      history.replace("/login");
    }
  }, [auth, history]);

  React.useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {

       

        const { data: customers } = await api.get<Customer[]>("/customers",{
          headers:{
            Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`
          }
        });
        const { data: influencers } = await api.get<Influencer[]>(
          "/influencers",{
            headers:{
              Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`
            }
          }
        );
        const { data: abandoned } = await api.get<Customer[]>("/abandoned",{
          headers:{
            Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`
          }
        });

        setCustomers(
          customers.filter(
            (customer) => customer.name != null || customer.phone != null
          )
        );
        setInfluencers(influencers);
        setAbandoned(abandoned);
        setLoading(false);
      } catch (error) {
        toast.error("Unable to fetch customers, try again later");
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  React.useEffect(() => {
    if (activeSidebar === "customers" && searchInput) {
      const filteredValues = customers.filter(
        (customer) =>
          customer.email
            .toLowerCase()
            .includes(searchInput.toLowerCase().trim()) ||
          customer.name.toLowerCase().includes(searchInput.toLowerCase().trim())
      );
      setSearchData(filteredValues);
    } else if (activeSidebar === "influencers" && searchInput) {
      const filteredValues = influencers.filter(
        (influencer) =>
          influencer.email
            .toLowerCase()
            .includes(searchInput.toLowerCase().trim()) ||
          influencer.first_name
            .toLowerCase()
            .includes(searchInput.toLowerCase().trim()) ||
          influencer.last_name
            .toLowerCase()
            .includes(searchInput.toLowerCase().trim())
      );
      setSearchData(filteredValues);
    } else if (activeSidebar === "abandoned" && searchInput) {
      const filteredValues = influencers.filter(
        (customer) =>
          customer.email
            .toLowerCase()
            .includes(searchInput.toLowerCase().trim()) ||
          customer.first_name
            .toLowerCase()
            .includes(searchInput.toLowerCase().trim()) ||
          customer.last_name
            .toLowerCase()
            .includes(searchInput.toLowerCase().trim())
      );
      setSearchData(filteredValues);
    } else setSearchData([]);
  }, [searchInput, activeSidebar, customers, influencers]);

  const paginatedDocuments = React.useCallback(() => {
    if (searchInput) {
      return searchData.slice(
        K.PAGE_COUNT * (activePage - 1),
        K.PAGE_COUNT * activePage
      );
    }
    if (activeSidebar === "customers") {
      return customers.slice(
        K.PAGE_COUNT * (activePage - 1),
        activePage * K.PAGE_COUNT
      );
    } else if (activeSidebar === "abandoned") {
      return abandoned.slice(
        K.PAGE_COUNT * (activePage - 1),
        activePage * K.PAGE_COUNT
      );
    } else if (activeSidebar === "influencers") {
      return influencers.slice(
        K.PAGE_COUNT * (activePage - 1),
        activePage * K.PAGE_COUNT
      );
    } else return [];
  }, [
    searchInput,
    activePage,
    searchData,
    activeSidebar,
    customers,
    influencers,
    abandoned,
  ]);

  return (
    <>
      <Helmet title="Dashboard | Mochi Admin" />
      <div className="header">
        <NavigationBar
          searchInput={searchInput}
          setSearchInput={setSearchInput}
          documents={
            searchInput
              ? searchData
              : activeSidebar === "customers"
              ? customers
              : activeSidebar === "abandoned"
              ? abandoned
              : activeSidebar === "influencers"
              ? influencers
              : []
          }
        />
      </div>
      <div className="container dashboard-container">
        <aside className="sidebar">
          <SideBar />
        </aside>
        <main className="main">
          {activeSidebar === "lookup" ? (
            <Lookup />
          ) : activeSidebar === "influencers" ? (
            <Confirmed />
          ) : activeSidebar === "templates" ? (
            <TemplatesTable />
          )  : activeSidebar === "emails" ? (
            <EmailHistoryTable />
          ) : (
            <DashboardTable
            searchInput={searchInput}
              urgentCustomers={
                activeSidebar === "customers"
                  ? customers.filter(
                      (customer) =>
                        (((new Date(customer.next_box_date).getTime() -
                          new Date().getTime()) /
                          (1000 * 3600 * 24)) |
                          0) ===
                        0
                    )
                  : []
              }
              activeSidebar={activeSidebar}
              customers={
                activeSidebar === "customers"
                  ? searchInput
                    ? searchData
                    : customers
                  : abandoned
              }
              loading={loading}
            />
          )}
        </main>
      </div>
    </>
  );
};

export default Dashboard;
