/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Paper from "@material-ui/core/Paper";
import { Customer } from "../@types";
import { format } from "date-fns";
import { useHistory } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import styled from "styled-components";
import { TableHeader } from "semantic-ui-react";
import UsersIcon from "./SVGIcons/UsersIcon";
import api from "../network/api";
import { renderSubscriptionType } from "../shared/functions";

interface HeadCell {
  disablePadding: boolean;
  id: keyof Customer;
  label: string;
  numeric: boolean;
}

const headCells: HeadCell[] = [
  { id: "name", numeric: false, disablePadding: false, label: "Name" },
  { id: "email", numeric: false, disablePadding: false, label: "Email" },
  {
    id: "subscriptionType",
    numeric: false,
    disablePadding: false,
    label: "Plan",
  },
  {
    id: "subscriptionStart",
    numeric: false,
    disablePadding: false,
    label: "Subscription Start",
  },
  {
    id: "daysUntilNextBox",
    numeric: false,
    disablePadding: false,
    label: "Days Until Next Box",
  },
  { id: "received", numeric: false, disablePadding: false, label: "Received" },
];

function EnhancedTableHead() {
  const classes = useStyles();

  return (
    <TableHead>
      <TableRow className={classes.tableRow}>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            className={classes.headerCell}
          >
            <TableSortLabel className={classes.cell}>
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      marginTop: "8rem",
      background: "#000",
    },
    paper: {
      width: "100%",
      marginBottom: theme.spacing(2),
      background: "#000",
    },
    table: {
      width: "100%",
      background: "#000",
      color: "#fff",
      borderSpacing: "0 0px",
      borderCollapse: "separate",
      borderRadius: "15px",
      overflow: "hidden",
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
    tableRow: {
      minWidth: "100%",
      background: "#E9F4F9",
      color: "white",
      transition: "all 0.2s all",
      opacity: "0.95",
      "&:hover": {
        cursor: "pointer",
        transition: "all 0.2s all",
        background: "#E9F4F9",
        opacity: "1",
      },
    },
    tableRowUrgent: {
      minWidth: "100%",
      background: "#ffcc00",
      color: "white",
      transition: "all 1s all",
      borderRadius: "20px",
      opacity: "0.95",
      paddingInline: "20px",
      paddingBlock: "20px",
      "&:hover": {
        cursor: "pointer",
        background: "#ffcc00",
        transition: "all 1s all",

        opacity: "1",
      },
    },
    cell: {
      color: "#000",
      fontWeight: "bold",
      paddingInline: "20px",
      paddingBlock: "20px",
    },
    headerCell: {
      color: "#000",
      fontWeight: "bold",
      "&:hover": {
        cursor: "pointer",
      },
    },
  })
);

interface Props {
  customers: Customer[];
  urgentCustomers: Customer[];
  loading?: boolean;
  hideHeader?: boolean;
  activeSidebar: string;
  searchInput: string;
}

const DashboardTable: React.FC<Props> = ({
  customers,
  urgentCustomers,
  loading,
  hideHeader,
  activeSidebar,
  searchInput,
}) => {
  const classes = useStyles();
  const history = useHistory();

  const onCustomerSelected = (customerId: number, email: string) => {
    api.post("/customers/read", {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`,
      },

      email: email,
      isRead: true,
    });
    history.push(`/customers/${customerId}`);
  };

  const onUserSelect = (customerId: number, email: string) => {
    api.post("/customers/read", {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`,
      },
      email: email,
      isRead: true,
    });
    history.push(`/influencer/${email}`);
  };

  

  // const dashboardHeaders = ["Name", "Email", "Subscription Type", "Date"];

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer>
          {loading ? (
            <LoadDiv>
              <BeatLoader color="#ffcc00" />
            </LoadDiv>
          ) : (
            <table className={classes.table}>
              <div>
                {/* <table className={classes.table}>
                  {customers.length + urgentCustomers.length > 0 && (
                    <TableHeader>
                      <TableRow>
                        <TableCell style={{ fontWeight: "bold" }}>
                          {dashboardHeaders[0]}
                        </TableCell>
                        <TableCell style={{ fontWeight: "bold" }}>
                          {dashboardHeaders[1]}
                        </TableCell>
                        <TableCell style={{ fontWeight: "bold" }} >
                          {dashboardHeaders[2]}
                        </TableCell>
                        <TableCell style={{ fontWeight: "bold" }}>
                          {dashboardHeaders[3]}
                        </TableCell>
                      </TableRow>
                    </TableHeader>
                  )}
                  
                </table> */}

                <p
                  style={{
                    fontWeight: "bold",
                    fontSize: "1.2rem",
                    marginLeft: "20px",
                    marginBottom: "10px",
                    display: urgentCustomers.length > 0 ? "flex" : "none",
                  }}
                >
                  Ready to send next box:&nbsp;{" "}
                  <div style={{ color: "#ffcc00" }}>
                    {urgentCustomers.length}
                  </div>
                </p>
                <table className={classes.table}>
                  {urgentCustomers.map((customer) => (
                    <tr
                      key={customer.id}
                      aria-checked={true}
                      onClick={() =>
                        activeSidebar === "abandoned"
                          ? onUserSelect(customer.id, customer.email)
                          : onCustomerSelected(customer.id, customer.email)
                      }
                      className={classes.tableRowUrgent}
                    >
                      <td align="left" className={classes.cell}>
                        {customer.name}
                      </td>
                      <td align="left" className={classes.cell}>
                        {customer.email}
                      </td>
                      <td align="left" className={classes.cell}>
                        {renderSubscriptionType(customer.subscriptionType)}
                      </td>
                      <td align="left" className={classes.cell}>
                        {customer.subscriptionStart
                          ? format(
                              new Date(customer.subscriptionStart),
                              "dd-MM-yyyy"
                            )
                          : "-----"}
                      </td>

                      <td
                        style={{ display: "flex", flexDirection: "row" }}
                        align="left"
                        className={classes.cell}
                      >
                        <div
                          style={{
                            marginLeft: "10px",
                            paddingInline: "13px",
                            paddingBlock: "2px",
                            display: "flex",
                            flexDirection: "row",
                            backgroundColor: "white",
                            borderRadius: "5px",
                            fontWeight: "normal",
                            color: "#3b7bbf",
                            width: "80px",
                            textAlign: "center",
                            justifyContent: "center",
                          }}
                        >
                          <img
                            alt="paypal"
                            height="20px"
                            width="20px"
                            style={{ marginRight: "5px" }}
                            src={
                              customer.isPayPal
                                ? "../../assets/images/paypal.png"
                                : customer.isAmazon
                                ? "../../assets/images/amazon.png"
                                : customer.walletName === "googlePay"
                                ? "../../assets/images/googlelogo.png"
                                : "../../assets/images/stripelogo.png"
                            }
                          />
                          {customer.isPayPal
                            ? "Paypal"
                            : customer.isAmazon
                            ? "Amazon"
                            : customer.walletName === "googlePay"
                            ? "Pay"
                            : "Stripe"}
                        </div>
                      </td>
                    </tr>
                  ))}
                </table>
                <br
                  style={{
                    display: urgentCustomers.length > 0 ? "flex" : "none",
                  }}
                />
                <p
                  style={{
                    fontWeight: "bold",
                    fontSize: "1.2rem",
                    marginLeft: "20px",
                    marginBottom: "10px",
                    display:
                    customers.filter((customer) => parseInt(customer.boxesShipped || "0") < 1).length >
                      0
                        ? "flex"
                        : "none",
                  }}
                >
                  New customers:&nbsp;{" "}
                  <div style={{ color: "#ffcc00" }}>
                    {customers.filter((customer) => parseInt(customer.boxesShipped || "0") < 1).length}
                  </div>
                </p>
                <table className={classes.table}>
                  {customers
                    .filter((customer) => parseInt(customer.boxesShipped || "0") < 1)
                    .map((customer) => (
                      <tr
                        key={customer.id}
                        aria-checked={true}
                        onClick={() =>
                          activeSidebar === "abandoned"
                            ? onUserSelect(customer.id, customer.email)
                            : onCustomerSelected(customer.id, customer.email)
                        }
                        className={classes.tableRowUrgent}
                      >
                        <td align="left" className={classes.cell}>
                          {customer.name}
                        </td>
                        <td align="left" className={classes.cell}>
                          {customer.email}
                        </td>
                        <td align="left" className={classes.cell}>
                          {renderSubscriptionType(customer.subscriptionType)}
                        </td>
                        <td align="left" className={classes.cell}>
                          {customer.subscriptionStart
                            ? format(
                                new Date(customer.subscriptionStart),
                                "dd-MM-yyyy"
                              )
                            : "-----"}
                        </td>

                        <td
                          style={{ display: "flex", flexDirection: "row" }}
                          align="left"
                          className={classes.cell}
                        >
                          <div
                            style={{
                              marginLeft: "10px",
                              paddingInline: "13px",
                              paddingBlock: "2px",
                              display:
                                activeSidebar === "abandoned" ? "none" : "flex",

                              flexDirection: "row",
                              backgroundColor: "white",
                              borderRadius: "5px",
                              fontWeight: "normal",
                              color: "#3b7bbf",
                              width: "80px",
                              textAlign: "center",
                              justifyContent: "center",
                            }}
                          >
                            <img
                              alt="paypal"
                              height="20px"
                              width="20px"
                              style={{ marginRight: "5px" }}
                              src={
                                customer.isPayPal
                                  ? "../../assets/images/paypal.png"
                                  : customer.isAmazon
                                  ? "../../assets/images/amazon.png"
                                  : customer.walletName === "googlePay"
                                  ? "../../assets/images/googlelogo.png"
                                  : "../../assets/images/stripelogo.png"
                              }
                            />
                            {customer.isPayPal
                              ? "Paypal"
                              : customer.isAmazon
                              ? "Amazon"
                              : customer.walletName === "googlePay"
                              ? "Pay"
                              : "Stripe"}
                          </div>
                        </td>
                      </tr>
                    ))}
                </table>
                <br
                  style={{
                    display:
                      customers.filter((customer) => !customer.isRead).length >
                      0
                        ? "flex"
                        : "none",
                  }}
                />
                <p
                  style={{
                    fontWeight: "bold",
                    fontSize: "1.2rem",
                    marginLeft: "20px",
                    marginBottom: "10px",
                    display: customers.length > 0 ? "flex" : "none",
                  }}
                >
                  {searchInput ? "Search results" : "All Customers"}:&nbsp;{" "}
                  <div style={{ color: "#ffcc00" }}>{customers.length}</div>
                </p>
                <table className={classes.table}>
                  {" "}
                  <TableBody>
                    {customers.map((customer) => (
                      <TableRow
                        key={customer.id}
                        aria-checked={true}
                        onClick={() =>
                          activeSidebar === "abandoned"
                            ? onUserSelect(customer.id, customer.email)
                            : onCustomerSelected(customer.id, customer.email)
                        }
                        selected={false}
                        className={classes.tableRow}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          className={classes.cell}
                        >
                          {customer.name}
                        </TableCell>
                        <TableCell align="left" className={classes.cell}>
                          {customer.email}
                        </TableCell>
                        <TableCell align="left" className={classes.cell}>
                          {renderSubscriptionType(customer.subscriptionType)}
                        </TableCell>
                        <TableCell align="left" className={classes.cell}>
                          {customer.subscriptionStart
                            ? format(
                                new Date(customer.subscriptionStart),
                                "dd-MM-yyyy"
                              )
                            : "-----"}
                        </TableCell>

                        <TableCell
                          style={{ display: "flex", flexDirection: "row" }}
                          align="left"
                          className={classes.cell}
                        >
                          <div
                            style={{
                              marginLeft: "10px",
                              paddingInline: "13px",
                              paddingBlock: "2px",
                              display:
                                activeSidebar === "abandoned" ? "none" : "flex",
                              flexDirection: "row",
                              backgroundColor: "white",
                              borderRadius: "5px",
                              fontWeight: "normal",
                              color: "#3b7bbf",
                              width: "80px",
                              textAlign: "center",
                              justifyContent: "center",
                            }}
                          >
                            <img
                              alt="paypal"
                              height="20px"
                              width="20px"
                              style={{ marginRight: "5px" }}
                              src={
                                customer.isPayPal
                                  ? "../../assets/images/paypal.png"
                                  : customer.isAmazon
                                  ? "../../assets/images/amazon.png"
                                  : customer.walletName === "googlePay"
                                  ? "../../assets/images/googlelogo.png"
                                  : "../../assets/images/stripelogo.png"
                              }
                            />
                            {customer.isPayPal
                              ? "Paypal"
                              : customer.isAmazon
                              ? "Amazon"
                              : customer.walletName === "googlePay"
                              ? "Pay"
                              : "Stripe"}
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </table>
              </div>
            </table>
          )}
        </TableContainer>
      </Paper>
    </div>
  );
};

const LoadDiv = styled.div`
  height: 50vh;
  width: 100%;
  margin-top: "400px";
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default DashboardTable;
