import { IconButton, makeStyles, TextField } from "@material-ui/core";
import { SaveOutlined } from "@material-ui/icons";
import lookup from "country-code-lookup";
import React from "react";
import { CountryDropdown } from "react-country-region-selector";
import { toast } from "react-toastify";
import {  Influencer } from "../../@types";
import api from "../../network/api";

const useStyles = makeStyles({
  root: {
    margin: "0 auto",
    display: "flex",
    justifyContent: "space-between",
  },
  mainSection: {
    width: "80%",
  },
  igSummary: {
    width: "55%",
  },
  noteHeading: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  note: {
    minHeight: "12vh",
    background: "#fff",
    borderRadius: "10px",
    padding: 0,
    margin: 0,
  },
  icon: {
    color: "#fff",
  },
  trackingField: {
    background: "#E9F4F9",
    height: "4rem",
    borderRadius: "4px",
    margin: "1rem 1rem 1rem 1rem",
    display: "flex",
    alignItems: "center",
    padding: ".4rem",
    "& > p": {
      fontWeight: "bold",
    },
    "& > input": {
      border: "none",
      background: "#fff",
      padding: ".5rem",
      marginLeft: "1rem",
      borderRadius: "4px",
      width: "50%",
    },
    "& > button": {
      width: "20%",
      background: "#ffcc00",
      border: "none",
      padding: ".5rem",
      marginLeft: "1rem",
      fontWeight: "bold",
      borderRadius: "4px",
    },
  },
  formSection: {
    margin: "1rem 1rem 1rem 1rem",
    background: "#e9f4f9",
    borderRadius: "4px",
    padding: ".4rem",
  },
  row: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& > div": {
      width: "100%",
      margin: ".5rem",
    },
  },
  inputWrapper: {
    width: "47%",
    margin: ".5rem",
  },
  input: {
    border: "1px solid #eee",
    width: "100%",
    background: "#fff",
    paddingLeft: ".4rem",
    borderRadius: "4px",
    "& > input": {
      border: "none",
    },
  },
  sectionHead: {
    fontSize: "19px",
    padding: 0,
    margin: 0,
    marginLeft: ".4rem",
    marginTop: "1rem",
  },
  countryText: {
    marginLeft: ".5rem",
    display: "flex",
    alignItems: "center",
    "& > img": {
      width: "30px",
      height: "30px",
      margin: "0 .4rem",
    },
    "& > .link": {
      color: "#fc6532",
      margin: "0 .4rem",
      cursor: "pointer",
      fontWeight: "bold",
      textDecoration: "underline",
    },
    "& > .country_name": {
      margin: "0 .4rem",
    },
  },
});

interface Props {
  user?: Influencer;
  userData?: any;
}

const Checkout: React.FC<Props> = ({ user, userData }) => {
  const classes = useStyles();
  // const [editNote, setEditNote] = React.useState(false);
  const [billingCountry, setBillingCountry] = React.useState("US");
  const [billingCountrySelectorShown, setBillingCountrySelectorShown] =
    React.useState(false);
  const [loading] = React.useState(false);

  const updateInfo = async (email: string | undefined, data: any) => {
    try {
      await api.post(`/influencer/update/${email}`, {
        data,
      }, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`,
        },
      });

      toast.success("Info updated");
    } catch (error) {
      toast.error("Could not update info");
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.mainSection}>
        <div className={classes.trackingField}>
          <p>Tracking Link</p>
          <input type="text" />
          <button>Save</button>
        </div>
        <div className={classes.formSection}>
          <h4 className={classes.sectionHead}>Your Contact Information:</h4>
          <div className={classes.row}>
            <TextField
              className={classes.input}
              onChange={(e) => {
                window.sessionStorage.setItem(
                  `influencer-name${user?.email}`,
                  e.target.value
                );
              }}
              placeholder="Your first name"
              defaultValue={user?.first_name || ""}
              InputProps={{
                endAdornment: (
                  <IconButton
                  onClick={async () => {
                    updateInfo(user?.email, {
                      first_name: `${window.sessionStorage.getItem(
                        `influencer-name${user?.email}`
                      )}`,
                    });
                  }}
                  >
                    <SaveOutlined />
                  </IconButton>
                ),
              }}
            />
            <TextField
              className={classes.input}
              onChange={(e) => {
                window.sessionStorage.setItem(
                  `influencer-email${user?.email}`,
                  e.target.value
                );
              }}
              placeholder="Email"
              defaultValue={user?.email || ""}
              InputProps={{
                endAdornment: (
                  <IconButton
                    onClick={async () => {
                      updateInfo(user?.email, {
                        email: window.sessionStorage.getItem(
                          `influencer-email${user?.email}`
                        ),
                      });
                    }}
                  >
                    <SaveOutlined />
                  </IconButton>
                ),
              }}
            />
          </div>
          <div className={classes.row}>
            <div className={classes.inputWrapper}>
              <TextField
                className={classes.input}
                onChange={(e) => {
                  window.sessionStorage.setItem(
                    `influencer-ig${user?.email}`,
                    e.target.value
                  );
                }}
                defaultValue={user?.instagram_account || ""}
                placeholder="Instagram account"
                InputProps={{
                  endAdornment: (
                    <IconButton
                      onClick={async () => {
                        updateInfo(user?.email, {
                          instagram_account: window.sessionStorage.getItem(
                            `influencer-ig${user?.email}`
                          ),
                        });
                      }}
                    >
                      <SaveOutlined />
                    </IconButton>
                  ),
                }}
              />
            </div>
            <div className={classes.inputWrapper}>
              <TextField
                className={classes.input}
                onChange={(e) => {
                  window.sessionStorage.setItem(
                    `influencer-fb${user?.email}`,
                    e.target.value
                  );
                }}
                defaultValue={user?.facebook_account || ""}
                placeholder="Facebook account"
                InputProps={{
                  endAdornment: (
                    <IconButton
                      onClick={async () => {
                        updateInfo(user?.email, {
                          facebook_account: window.sessionStorage.getItem(
                            `influencer-fb${user?.email}`
                          ),
                        });
                      }}
                    >
                      <SaveOutlined />
                    </IconButton>
                  ),
                }}
              />
            </div>
          </div>

          <h4 className={classes.sectionHead}>Child's Information:</h4>
          <div className={classes.row}>
            <div>
              <TextField
                className={classes.input}
                onChange={(e) => {
                  window.sessionStorage.setItem(
                    `influencer-childname${user?.email}`,
                    e.target.value
                  );
                }}
                placeholder="Child's Name"
                defaultValue={user?.child_name || ""}
                InputProps={{
                  endAdornment: (
                    <IconButton
                      onClick={async () => {
                        updateInfo(user?.email, {
                          child_name: window.sessionStorage.getItem(
                            `influencer-childname${user?.email}`
                          ),
                        });
                      }}
                    >
                      <SaveOutlined />
                    </IconButton>
                  ),
                }}
              />
            </div>
            <div className={classes.row}>
              <TextField
                className={classes.input}
                select
                label="Day"
              ></TextField>
              <TextField
                className={classes.input}
                select
                label="Month"
              ></TextField>
              <TextField
                className={classes.input}
                label="Year"
                select
              ></TextField>
            </div>
          </div>
          <h4 className={classes.sectionHead}>Shipping Address:</h4>
          <p className={classes.countryText}>
            Your country is set to
            {(billingCountry === "US" || billingCountry === "CA") && (
              <img
                className="flag"
                alt="flag"
                src={
                  billingCountry === "US"
                    ? "/assets/images/us.png"
                    : "/assets/images/ca.png"
                }
              />
            )}
            <strong className="country_name">
              {lookup.byIso(billingCountry)?.country}.
            </strong>{" "}
            Click{" "}
            {billingCountrySelectorShown ? (
              <CountryDropdown
                value={billingCountry}
                onChange={(val) => {
                  setBillingCountry(val);
                  setBillingCountrySelectorShown(!billingCountrySelectorShown);
                }}
                classes="country_select"
                valueType="short"
              />
            ) : (
              <span
                className="link"
                onClick={
                  loading
                    ? undefined
                    : () =>
                        setBillingCountrySelectorShown(
                          !billingCountrySelectorShown
                        )
                }
              >
                here
              </span>
            )}{" "}
            to change
          </p>
          <div className={classes.row}>
            <TextField
              className={classes.input}
              placeholder="First Name for delivery"
              onChange={(e) => {
                window.sessionStorage.setItem(
                  `influencer-firstname${user?.email}`,
                  e.target.value
                );
              }}
              defaultValue={user?.first_name || ""}
              InputProps={{
                endAdornment: (
                  <IconButton
                    onClick={async () => {
                      updateInfo(user?.email, {
                        first_name: `${window.sessionStorage.getItem(
                          `influencer-firstname${user?.email}`
                        )}`,
                      });
                    }}
                  >
                    <SaveOutlined />
                  </IconButton>
                ),
              }}
            />
            <TextField
              className={classes.input}
              placeholder="Last Name for delivery"
              onChange={(e) => {
                window.sessionStorage.setItem(
                  `influencer-lastname${user?.email}`,
                  e.target.value
                );
              }}
              defaultValue={user?.last_name|| ""}
              InputProps={{
                endAdornment: (
                  <IconButton
                    onClick={async () => {
                      updateInfo(user?.email, {
                        last_name: ` ${window.sessionStorage.getItem(
                          `influencer-lastname${user?.email}`
                        )}`,
                      });
                    }}
                  >
                    <SaveOutlined />
                  </IconButton>
                ),
              }}
            />
          </div>
          <div className={classes.row}>
            <TextField
              className={classes.input}
              placeholder="Address"
              onChange={(e) => {
                window.sessionStorage.setItem(
                  `influencer-address${user?.email}`,
                  e.target.value
                );
              }}
              defaultValue={user?.address}
              InputProps={{
                endAdornment: (
                  <IconButton
                    onClick={async () => {
                      updateInfo(user?.email, {
                        address: window.sessionStorage.getItem(
                          `influencer-address${user?.email}`
                        ),
                      });
                    }}
                  >
                    <SaveOutlined />
                  </IconButton>
                ),
              }}
            />
            <TextField
              className={classes.input}
              placeholder="Apartment"
              InputProps={{
                endAdornment: (
                  <IconButton>
                    <SaveOutlined />
                  </IconButton>
                ),
              }}
            />
          </div>
          <div className={classes.row}>
            <TextField
              className={classes.input}
              defaultValue={user?.city}
              onChange={(e) => {
                window.sessionStorage.setItem(
                  `influencer-city${user?.email}`,
                  e.target.value
                );
              }}
              placeholder="City"
              InputProps={{
                endAdornment: (
                  <IconButton
                    onClick={async () => {
                      updateInfo(user?.email, {
                        city: window.sessionStorage.getItem(
                          `influencer-city${user?.email}`
                        ),
                      });
                    }}
                  >
                    <SaveOutlined />
                  </IconButton>
                ),
              }}
            />
            <div className={classes.row}>
              <TextField
                className={classes.input}
                onChange={(e) => {
                  window.sessionStorage.setItem(
                    `influencer-state${user?.email}`,
                    e.target.value
                  );
                }}
                defaultValue={user?.state}
                placeholder="State"
                InputProps={{
                  endAdornment: (
                    <IconButton onClick={async () => {
                      updateInfo(user?.email, {
                        state: window.sessionStorage.getItem(
                          `influencer-state${user?.email}`
                        ),
                      });
                    }}>
                      <SaveOutlined />
                    </IconButton>
                  ),
                }}
              />
              <TextField
                className={classes.input}
                placeholder="Postcode"
                InputProps={{
                  endAdornment: (
                    <IconButton>
                      <SaveOutlined />
                    </IconButton>
                  ),
                }}
              />
            </div>
          </div>
          <TextField
            className={classes.input}
            onChange={(e) => {
              window.sessionStorage.setItem(
                `influencer-phone${user?.email}`,
                e.target.value
              );
            }}
            defaultValue={user?.phone}
            placeholder="Phone number"
            InputProps={{
              endAdornment: (
                <IconButton onClick={async () => {
                  updateInfo(user?.email, {
                    phone: window.sessionStorage.getItem(
                      `influencer-phone${user?.email}`
                    ),
                  });
                }}>
                  <SaveOutlined />
                </IconButton>
              ),
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Checkout;
