import React from "react";
import { Tabs, TabPanel, TabList, Tab } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import styled from "styled-components";
import { Influencer } from "../@types";
import api from "../network/api";
import CorrespondenceTable from "./CorrespondenceTable";
import Ordered from "./dashboard/Ordered";
import Selection from "./dashboard/selection";
import EmailTable from "./Emailtable";

const InfluencerTabs: React.FC = () => {
  const [influencers, setInfluencers] = React.useState<Influencer[]>([]);
  const [originalInfluencers, setOriginalInfluencers] = React.useState<
    Influencer[]
  >([]);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    const getInfluencers = async () => {
      const { data: influencers } = await api.get<Influencer[]>(
        "/influencers",
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`,
          },
        }
      );
      setOriginalInfluencers(influencers);
      setInfluencers(influencers);
      setLoading(false);
      console.log(influencers);
    };
    getInfluencers();
  }, []);

  return (
    <TabsDiv>
      <div className="tabs-section">
        <Tabs
          defaultIndex={parseInt(
            window.sessionStorage.getItem("influencer-tab-persistence") || "0"
          )}
          onSelect={(index: number) =>
            window.sessionStorage.setItem(
              "influencer-tab-persistence",
              index.toString()
            )
          }
          className="tabs"
        >
          <StyledTabList>
            <Tab>All Accounts</Tab>
            <Tab>1st Email</Tab>
            <Tab>2nd Email</Tab>
            <Tab>Correspondence</Tab>
            <Tab>Ordered</Tab>
          </StyledTabList>
          <TabPanel>
            <Selection
              influencers={influencers}
              originalInfluencers={originalInfluencers}
              loading={loading}
            />
          </TabPanel>
          <TabPanel>
            <EmailTable
              influencers={influencers}
              loading={loading}
              emailcount={1}
            />
          </TabPanel>
          <TabPanel>
            <EmailTable
              influencers={influencers}
              loading={loading}
              emailcount={2}
            />
          </TabPanel>
          <TabPanel>
            <CorrespondenceTable />
          </TabPanel>
          <TabPanel>
            <Ordered
              influencers={influencers}
              originalInfluencers={originalInfluencers}
              loading={loading}
            />
          </TabPanel>
        </Tabs>
      </div>
    </TabsDiv>
  );
};

const TabsDiv = styled.div`
  margin-top: 6rem;
  & > .tabs-section {
    display: flex;
    align-items: center;
    /* & > .pagination {
            width: 20%;
        } */
    & > .tabs {
      width: 100%;
    }
  }
`;
const StyledTabList = styled(TabList)`
  border-bottom: 8px solid #ffcc00;
  & > .react-tabs__tab {
    margin: 0.4rem;
    border-bottom: none;
    border-top-left-radius: 11px;
    border-top-right-radius: 11px;
    margin-bottom: 0;
    background-color: #fff;
    padding-left: 3rem;
    margin-top: 2rem;
    font-size: 1rem;
    padding-right: 3rem;
    font-weight: bold;
    color: #000;
    &--selected {
      background: #ffcc00;
    }
  }
`;

export default InfluencerTabs;
