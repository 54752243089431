import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";
import validator from "validator";
import { InstagramProfile } from "../../@types";
import api from "../../network/api";
import CircularLoading from "../Animations/Loading";
import SearchBar from "../search-bar";
import ChevronDoubleUp from "../SVGIcons/chevron_double_up";
import LookUpIcon from "../SVGIcons/lookup";
import SaveIcon from "../SVGIcons/SaveIcon";

const Lookup: React.FC = () => {
  const [username, setUsername] = useState("");
  const [profiles, setProfiles] = useState<InstagramProfile[]>([]);
  const [profilesToShow, setProfilesToShow] = useState(20);
  const [emailInputs, setEmailInputs] = useState<{ [id: number]: string }>({});
  const [reloadCount, setReloadCount] = useState(0);
  const [originalProfiles, setOriginalProfiles] = useState<InstagramProfile[]>(
    []
  );

  //visual state management
  const [isLoadingProfiles, setIsLoadingProfiles] = useState(true);
  const [isFetchingProfile, setIsFetchingProfile] = useState(false);
  const [isAddingProfile, setIsAddingProfile] = useState(-1);
  const [isRemovingProfile, setIsRemovingProfile] = useState(-1);
  const [offsetY, setOffsetY] = useState(0);

  const handleScroll = () => setOffsetY(window.pageYOffset);

  const searchProfile = async () => {
    if (!username) {
      toast.error("No username entered");
      return;
    }
    if (username.trim().indexOf(" ") !== -1) {
      toast.error("Please enter a valid instagram handle");
      return;
    }
    setIsFetchingProfile(true);

    try {
      const { data } = await api.post<InstagramProfile>(
        "/add-instagram-profile",
        {
          username,
        }
      );
      setProfiles([...profiles, data]);
      setIsFetchingProfile(false);

      toast.success("Profile added");
      setUsername("");
      setReloadCount(reloadCount + 1);
    } catch (error) {
      toast.error("Unable to add profile");
      setIsFetchingProfile(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    const getProfiles = async () => {
      const { data } = await api.get<InstagramProfile[]>("/instagram-profiles");
      setProfiles(data);
      setOriginalProfiles(data);
      setIsLoadingProfiles(false);
    };
    getProfiles();
    return () => window.removeEventListener("scroll", handleScroll);
  }, [reloadCount]);

  const setInputForIndex = (field: string, index: number) => {
    setEmailInputs({ ...emailInputs, [index]: field });
  };

  const saveEmailField = async (id: number) => {
    console.log(id);
    const email = emailInputs[id];
    if (!email || !validator.isEmail(email)) {
      toast.error("Please enter a valid email");
      return;
    }
    try {
      await api.put(`/update-instagram-profile/${id}`, { email });
      toast.success("Updated email field");
      setReloadCount(reloadCount + 1);
    } catch (error) {
      toast.error("Unable to update the email address");
    }
  };

  const saveEngagementField = async (id: number) => {
    console.log(id);
    const rate = window.sessionStorage.getItem(
      `MOCHI_ADMIN_LOOKUP_MANUAL_ENGAGEMENT_${id}`
    );
    if (!rate || !validator.isNumeric(rate)) {
      toast.error("Please enter a valid engagement");
      return;
    }
    try {
      await api.put(`/update-instagram-profile/${id}`, { rate });
      toast.success("Updated engagement field");
      setReloadCount(reloadCount + 1);
    } catch (error) {
      toast.error("Unable to update the engagement address");
    }
  };

  const handleSearch = (query: string) => {
    const results = originalProfiles.filter(
      (profile) =>
        profile.username.toLowerCase().includes(query.toLowerCase()) ||
        profile.name?.toLowerCase().includes(query.toLowerCase()) ||
        profile.email?.toLowerCase().includes(query.toLowerCase())
    );
    setProfiles(results);
  };

  const select = async (id: number) => {
    setIsAddingProfile(id);
    await api.post(`/instagram-profiles/select/${id}`);
    toast.success("Added profile to selected list");
    setReloadCount(reloadCount + 1);
    setIsAddingProfile(-1);
  };

  const remove = async (id: number) => {
    setIsRemovingProfile(id);
    await api.delete(`/instagram-profiles/remove/${id}`);
    toast.success("Profile removed!");
    setReloadCount(reloadCount + 1);
    setIsRemovingProfile(-1);
  };

  return (
    <StyledLookup>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "absolute",
          width: "80vw",
          flexDirection: "column",
          opacity: isLoadingProfiles ? "1" : "0",
          transition: "all 0.3s ease",
          transform: isLoadingProfiles
            ? "translateY(30vh)"
            : "translateY(20vh)",
        }}
      >
        <CircularLoading />
      </div>
      <div
        style={{
          zIndex: 1,
          display: "flex",
          alignItems: "center",
          position: "fixed",
          backgroundColor: "black",
          width: "100vw",
          transform: "translate(-1rem,-1rem)",
        }}
        className="top-section"
      >
        <SearchBar
          searchInput={username}
          setSearchInput={setUsername}
          placeholder="Instagram handle"
        />

        {isFetchingProfile ? (
          <div
            style={{
              marginInline: "20px",
              marginBottom: "30px",
            }}
          >
            <CircularLoading />
          </div>
        ) : (
          <button onClick={searchProfile}>Search Profile</button>
        )}
        <div
          style={{
            right: "20",
            alignItems: "end",
            alignSelf: "flex-end",
            display: "flex",
            marginLeft: "9rem",
            justifyContent: "flex-end",
          }}
        >
          <div className="search-bar">
            <div className="space-between row padding row-input">
              <input
                className="input"
                placeholder="Search"
                type="text"
                style={{ fontSize: "1.1rem" }}
                onChange={(e) => handleSearch(e.target.value)}
              />
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          opacity: isLoadingProfiles ? "0" : "1",
          transition: "all 0.3s ease",
          transform: isLoadingProfiles ? "translateY(60px)" : "translateY(0px)",
        }}
        className="main-section"
      >
        {!profiles.length ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <div style={{ height: "50px", width: "50px", marginTop: "5rem" }}>
              <LookUpIcon />
            </div>
            <h2 className="empty-loading">No profiles in lookup</h2>
          </div>
        ) : (
          <>
            <ol style={{ transform: "translatey(5rem)" }}>
              {profiles.slice(0, profilesToShow).map((profile, index) => (
                <div key={profile.id} className="list-item">
                  <div>
                    <div
                      style={{
                        height: "40px",
                        justifyContent: "center",
                        alignItems: "center",
                        fontWeight: "bold",
                        display: "flex",
                        marginRight: "10px",
                        width: "40px",
                        backgroundColor: "#ffcc00",
                        borderRadius: "50%",
                        color: "black",
                      }}
                    >
                      {index + 1}
                    </div>
                    <span style={{ fontSize: "1rem" }}>
                      {" "}
                      <div
                        onClick={() =>
                          window.open(
                            `https://instagram.com/${profile.username}`,
                            "_blank"
                          )
                        }
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                      >
                        https://instagram.com/
                        <div style={{ color: "#ffcc00", fontSize: "1.2rem" }}>
                          {profile.username}
                        </div>
                      </div>
                    </span>
                    <SearchBar
                      searchInput={
                        emailInputs[profile.id] || profile.email || ""
                      }
                      setSearchInput={(input) =>
                        setInputForIndex(input, profile.id)
                      }
                      EndIcon={() => (
                        <StyledBtn onClick={() => saveEmailField(profile.id)}>
                          {profile.email ? "Edit" : "Save"}
                        </StyledBtn>
                      )}
                      placeholder="Email"
                      isStatic
                      width={500}
                    />
                    <div
                      className="search-bar"
                      style={{ width: 200, marginLeft: "20px" }}
                    >
                      <div className="space-between row padding row-input">
                        <input
                          defaultValue={profile.rate}
                          className="input"
                          placeholder="Engagement"
                          type="text"
                          style={{ fontSize: "1.1rem" }}
                          onChange={(e) => {
                            window.sessionStorage.setItem(
                              `MOCHI_ADMIN_LOOKUP_MANUAL_ENGAGEMENT_${profile.id}`,
                              e.target.value
                            );
                          }}
                        />
                        <div
                          onClick={() => {
                            saveEngagementField(profile.id);
                          }}
                          style={{
                            height: "30px",
                            width: "30px",
                            cursor: "pointer",
                          }}
                        >
                          <SaveIcon />
                        </div>
                      </div>
                    </div>

                    {isAddingProfile === profile.id ? (
                      <div
                        style={{
                          marginInline: "30px",
                          marginBottom: "30px",
                        }}
                      >
                        <CircularLoading />
                      </div>
                    ) : (
                      <PlusButton onClick={() => select(profile.id)}>
                        +
                      </PlusButton>
                    )}

                    {isRemovingProfile === profile.id ? (
                      <div
                        style={{
                          marginInline: "30px",
                          marginBottom: "30px",
                        }}
                      >
                        <CircularLoading />
                      </div>
                    ) : (
                      <PlusButton onClick={() => remove(profile.id)}>
                        x
                      </PlusButton>
                    )}
                  </div>
                </div>
              ))}
            </ol>
          </>
        )}
      </div>

      <div
        onClick={() => window.scrollTo({ top: 0, left: 0, behavior: "smooth" })}
        style={{
          backgroundColor: "#ffcc00",
          position: "fixed",
          cursor: "pointer",
          bottom: "40px",
          right: "40px",
          transform: offsetY > 200 ? "translateY(0px)" : "translateY(100px)",
          pointerEvents: offsetY > 200 ? "auto" : "none",
          transition: "all 0.2s ease",
          opacity: offsetY > 200 ? "1" : "0.4",
          color: "black",
          fontWeight: "bold",
          paddingInline: "1rem",
          paddingBlock: "1rem",
          borderRadius: "50%",
        }}
      >
        <div style={{ height: "25px", width: "25px" }}>
          <ChevronDoubleUp />
        </div>
      </div>
      <div
        onClick={() => {
          profiles.length - profilesToShow > 20
            ? setProfilesToShow(profilesToShow + 20)
            : setProfilesToShow(
                profilesToShow + (profiles.length - profilesToShow)
              );
        }}
        style={{
          backgroundColor: "#ffcc00",
          position: "fixed",
          cursor: "pointer",
          bottom: "40px",
          right: "40px",
          transform:
            Math.ceil(window.innerHeight + window.scrollY) >=
              document.documentElement.scrollHeight &&
            profilesToShow !== profiles.length &&
            profiles.length > 20
              ? "translateX(-70px)"
              : "translateX(50px)",
          pointerEvents:
            Math.ceil(window.innerHeight + window.scrollY) >=
            document.documentElement.scrollHeight
              ? "auto"
              : "none",
          transition: "all 0.3s ease",
          opacity:
            Math.ceil(window.innerHeight + window.scrollY) >=
              document.documentElement.scrollHeight &&
            profilesToShow !== profiles.length &&
            profiles.length > 20
              ? "1"
              : "0",
          color: "black",
          fontWeight: "bold",
          paddingInline: "1rem",
          paddingBlock: "1rem",
          borderRadius: "1rem",
        }}
      >
        <div style={{ height: "25px", alignItems: "center", display: "flex" }}>
          Load More
        </div>
      </div>
    </StyledLookup>
  );
};

const StyledLookup = styled.div`
  background-color: #000;
  min-height: 80vh;
  margin-top: 6rem;
  & > .top-section {
    display: flex;
    align-items: center;
    border-bottom: 2px solid #e9f4f9;
    padding: 1rem;
    & > input {
      width: 25rem;
      padding-left: 1rem;
    }
    & > button {
      background-color: #ffbf00;
      font-weight: bold;
      padding: 0.8rem 1rem;
      margin-left: 1rem;
      border: none;
      border-radius: 0.4rem;
      &:hover {
        cursor: pointer;
      }
    }
  }
  & > .main-section {
    margin-top: 1rem;
    & > .empty-loading {
      margin: 0 auto;
      text-align: center;
      margin-top: 2rem;
    }
    & > ol > .list-item > div {
      padding: 0.5rem;
      display: flex;
      align-items: center;
      & > span {
        width: 30%;
        font-weight: bold;
      }
      & > input {
        width: 40%;
        margin-left: 1rem;
        padding-left: 1rem;
      }
    }
  }
`;
const StyledBtn = styled.button`
  background-color: #cde7f3;
  border: none;
  padding: 0.4rem 0.8rem;
  font-weight: bold;
  border-radius: 0.4rem;
  cursor: pointer;
`;
const PlusButton = styled.button`
  padding: 0.4rem 0.8rem;
  margin-left: 1rem;
  border: none;
  background: #ffbf00;
  border-radius: 10rem;
  cursor: pointer;
  font-size: 1.3rem;
  font-weight: bold;
`;

export default Lookup;
