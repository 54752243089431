import React, { useState } from "react";
import SearchBar from "./search-bar";
import Button from "@material-ui/core/Button";
import Pagination from "@material-ui/lab/Pagination";
import makeStyles from "@material-ui/core/styles/makeStyles";
import K from "../constants";
import { Customer } from "../@types";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../store/root";
import { setActivePageNumber } from "../store/modules/misc.reducer";
import { capitalCase } from "change-case";
import Popup from "reactjs-popup";
import api from "../network/api";
import { toast } from "react-toastify";
import CircularLoading from "./Animations/Loading";

interface Props {
  searchInput: string;
  setSearchInput: (search: string) => void;
  documents: Customer[];
  onFilterChange?: Function;
}

const useStyles = makeStyles((theme) => ({
  customers: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: "1rem",
    paddingRight: "2rem",
  },
  select: {
    width: "12rem",
    color: "#000",
    borderRadius: "1.2rem",
  },

  root: {
    "& .Mui-selected": {
      backgroundColor: "#ffcc00",
      color: "#fff",
      fontWeight: "bold",
    },
    "& ul > li:not(:first-child):not(:last-child) > button:not(.Mui-selected)":
      {
        backgroundColor: "transparent",
        color: "#fff",
        fontWeight: "bold",
      },
  },
}));

const NavigationBar: React.FC<Props> = ({
  searchInput,
  setSearchInput,
  documents,
}) => {
  const { activePage, activeSidebar } = useSelector(
    (state: RootState) => state.misc
  );
  const dispatch = useDispatch();
  const classes = useStyles();

  const setActivePage = (page: number) => {
    dispatch(setActivePageNumber(page));
  };

  //New Influencer data
  const [email, setEmail] = useState("");
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [instagram, setInstagram] = useState("");
  const [facebook, setFacebook] = useState("");
  const [isAddInfluencerVisible, setIsAddInfluencerVisible] = useState(false);
  const [addingInfluencer, setAddingInfluencer] = useState(false);

  //New template data
  const [isAddTemplateVisible, setIsAddTemplateVisible] = useState(false);
  const [templateName, setTemplateName] = useState("");
  const [template, setTemplate] = useState("");
  const [addingTempalte, setAddingTemplate] = useState(false);

	const auth = useSelector((state: RootState) => state.auth);


  const addInfluencer = async () => {
    try {
      setAddingInfluencer(true);
      const influencer = await api.post("/influencer/new", {
        email: email,
        instagram_account: instagram,
        facebook_account: facebook,
        first_name: firstname,
        last_name: lastname,
      }, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`,
        },
      });
      toast.success(
        influencer.status === 202
          ? "Influencer already exists"
          : "Influencer added"
      );
      setAddingInfluencer(false);

      setIsAddInfluencerVisible(false);
    } catch (error) {
      toast.error("Could not add user");
      setAddingInfluencer(false);
    }
  };

  const addTemplate = async () => {
    const data = {
      templateName: templateName,
      template: template,
    };

    try {
      setAddingTemplate(true);
      const res = await api.post("/templates/new", data, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`,
        },
      });
      toast.success(
        res.status === 202 ? "Template already exists" : "Template added"
      );
      setAddingTemplate(false);

      setIsAddTemplateVisible(false);
    } catch (error: any) {
      toast.error(error.toString());
      setAddingTemplate(false);
    }
  };

  return (
    <div className="topnav">
      {activeSidebar === "confirmed" ? (
        <div className={classes.customers}>
          {/* <div className="padding">
                        <SearchBar setSearchInput={setSearchInput} searchInput={searchInput} />
                    </div> */}
        </div>
      ) : (
        <div className="column">
          <div style={{ width: "100%" }}>
            <div
              style={{
                width: "95%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
              className="row top-nav"
            >
              <h2 className="padding">{capitalCase(activeSidebar.toString())}</h2>
              <div style={{ width: "100%" }}>
                {activeSidebar !== "lookup" &&
                  activeSidebar !== "confirmed" && (
                    <>
                      {activeSidebar !== "influencers" && (
                        <SearchBar
                          setSearchInput={setSearchInput}
                          searchInput={searchInput}
                        />
                      )}
                    </>
                  )}
              </div>
              {
                auth.user?.isAdmin && <div style={{ width: "170px", marginLeft: "20px" }}>
                <Button
                  disableElevation={true}
                  variant="contained"
                  style={{
                    backgroundColor: "#ffc000",
                    color: "#fff",
                    borderRadius: "8px",
                    display:
                      activeSidebar === "influencers" ||
                      activeSidebar === "templates"
                        ? "none"
                        : "flex",
                  }}
                  target="_blank"
                  href="/create"
                >
                  Create
                </Button>

                {/* Add Influencer */}
                {addingInfluencer && activeSidebar === "influencers" ? (
                  <div style={{ marginRight: "50px", marginBottom: "30px" }}>
                    <CircularLoading />
                  </div>
                ) : (
                  <div
                    style={
                      {
                        // pointerEvents: isAddInfluencerVisible ? "auto" : "none",
                      }
                    }
                  >
                    <Popup
                      contentStyle={{
                        transition: "ease 1s all",
                        pointerEvents: isAddInfluencerVisible ? "auto" : "none",
                        backgroundColor: "#000",
                        borderRadius: "10px",
                        height: "600px",
                        width: "600px",
                        opacity: isAddInfluencerVisible ? "1" : "0",
                      }}
                      overlayStyle={{
                        opacity: isAddInfluencerVisible ? "1" : "0",
                      }}
                      onClose={() => setIsAddInfluencerVisible(false)}
                      trigger={
                        <div>
                          {" "}
                          <Button
                            disableElevation={true}
                            variant="contained"
                            onClick={() => setIsAddInfluencerVisible(true)}
                            style={{
                              backgroundColor: "#ffc000",
                              color: "#fff",
                              display:
                                activeSidebar === "influencers"
                                  ? "block"
                                  : "none",
                              borderRadius: "8px",
                            }}
                          >
                            + ADD
                          </Button>
                        </div>
                      }
                      modal
                      nested
                    >
                      {(close: any) => (
                        <div
                          style={{
                            margin: "30px",
                            color: "white",
                            height: "70vh",
                            display: "flex",
                            flexDirection: "column",
                            borderRadius: "10px",
                          }}
                        >
                          <div
                            style={{
                              // marginLeft: "98%",
                              width: "40px",
                              height: "40px",
                              cursor: "pointer",
                              color: "black",
                              textAlign: "center",
                              borderRadius: "50%",
                              backgroundColor: "#ffcc00",
                              fontWeight: "bold",
                              fontSize: "2rem",
                            }}
                            onClick={close}
                          >
                            &times;
                          </div>
                          <p
                            style={{
                              fontWeight: "bold",
                              fontSize: "1.7rem",
                            }}
                          >
                            {" "}
                            Add new influencer
                          </p>
                          <div
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <div>
                              <p style={{}}>Firstname (Optional)</p>
                              <input
                                placeholder="Firstname"
                                style={{
                                  // display: showSubjectLine ? "flex" : "none",
                                  paddingInline: "20px",
                                  // width: "100%",
                                  paddingBlock: "10px",
                                  borderRadius: "10px",
                                  backgroundColor: "white",
                                  color: "#000",
                                  fontWeight: "bold",
                                }}
                                // disabled={isOld || loading}
                                value={firstname}
                                onChange={(e) => {
                                  setFirstname(e.target.value);
                                }}
                              />
                            </div>

                            <div style={{ marginLeft: "60px" }}>
                              <p style={{}}>Lastname (Optional)</p>
                              <input
                                placeholder="Lastname"
                                style={{
                                  // display: showSubjectLine ? "flex" : "none",
                                  paddingInline: "20px",
                                  // width: "100%",
                                  paddingBlock: "10px",
                                  borderRadius: "10px",
                                  backgroundColor: "white",
                                  color: "#000",
                                  fontWeight: "bold",
                                }}
                                // disabled={isOld || loading}
                                value={lastname}
                                onChange={(e) => {
                                  setLastname(e.target.value);
                                }}
                              />
                            </div>
                          </div>
                          <div>
                            <p style={{}}>Email (Required)</p>
                            <input
                              placeholder="Email"
                              style={{
                                // display: showSubjectLine ? "flex" : "none",
                                paddingInline: "20px",
                                // width: "100%",
                                paddingBlock: "10px",
                                borderRadius: "10px",
                                backgroundColor: "white",
                                color: "#000",
                                fontWeight: "bold",
                              }}
                              // disabled={isOld || loading}
                              value={email}
                              onChange={(e) => {
                                setEmail(e.target.value);
                              }}
                            />
                          </div>
                          <div
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <div>
                              {" "}
                              <p style={{}}>Instagram handle (Optional)</p>
                              <input
                                placeholder="Instagam handle"
                                style={{
                                  // display: showSubjectLine ? "flex" : "none",
                                  paddingInline: "20px",
                                  // width: "100%",
                                  paddingBlock: "10px",
                                  borderRadius: "10px",
                                  backgroundColor: "white",
                                  color: "#000",
                                  fontWeight: "bold",
                                }}
                                // disabled={isOld || loading}
                                value={instagram}
                                onChange={(e) => {
                                  setInstagram(e.target.value);
                                }}
                              />
                            </div>
                            <div style={{ marginLeft: "60px" }}>
                              <p style={{}}>Facebook handle (Optional)</p>
                              <input
                                placeholder="Facebook handle"
                                style={{
                                  // display: showSubjectLine ? "flex" : "none",
                                  paddingInline: "20px",
                                  // width: "100%",
                                  paddingBlock: "10px",
                                  borderRadius: "10px",
                                  backgroundColor: "white",
                                  color: "#000",
                                  fontWeight: "bold",
                                }}
                                // disabled={isOld || loading}
                                value={facebook}
                                onChange={(e) => {
                                  setFacebook(e.target.value);
                                }}
                              />
                            </div>
                          </div>
                          <Button
                            disableElevation={true}
                            variant="contained"
                            onClick={() => email.length > 0 && addInfluencer()}
                            style={{
                              backgroundColor:
                                email.length > 0 ? "#ffc000" : "gray",
                              color: "#fff",
                              marginTop: "40px",
                              borderRadius: "8px",
                            }}
                          >
                            + ADD
                          </Button>
                        </div>
                      )}
                    </Popup>
                  </div>
                )}
                {/* Add template */}

                {addingTempalte && activeSidebar === "templates" ? (
                  <div style={{ marginRight: "50px", marginBottom: "30px" }}>
                    <CircularLoading />
                  </div>
                ) : (
                  <div>
                    <Popup
                      contentStyle={{
                        transition: "ease 1s all",
                        pointerEvents: isAddTemplateVisible ? "auto" : "none",
                        backgroundColor: "#000",
                        borderRadius: "10px",
                        // height: "600px",
                        width: "50vw",
                        opacity: isAddTemplateVisible ? "1" : "0",
                      }}
                      overlayStyle={{
                        opacity: isAddTemplateVisible ? "1" : "0",
                      }}
                      onClose={() => setIsAddTemplateVisible(false)}
                      trigger={
                        <div>
                          {" "}
                          <Button
                            disableElevation={true}
                            variant="contained"
                            onClick={() => setIsAddTemplateVisible(true)}
                            style={{
                              backgroundColor: "#ffc000",
                              color: "#fff",
                              display:
                                activeSidebar === "templates"
                                  ? "block"
                                  : "none",
                              borderRadius: "8px",
                            }}
                          >
                            + ADD
                          </Button>
                        </div>
                      }
                      modal
                      nested
                    >
                      {(close: any) => (
                        <div
                          style={{
                            margin: "30px",
                            color: "white",
                            height: "70vh",
                            display: "flex",
                            flexDirection: "column",
                            borderRadius: "10px",
                          }}
                        >
                          <div
                            style={{
                              // marginLeft: "98%",
                              width: "40px",
                              height: "40px",
                              cursor: "pointer",
                              color: "black",
                              textAlign: "center",
                              borderRadius: "50%",
                              backgroundColor: "#ffcc00",
                              fontWeight: "bold",
                              fontSize: "2rem",
                            }}
                            onClick={close}
                          >
                            &times;
                          </div>
                          <p
                            style={{
                              fontWeight: "bold",
                              fontSize: "1.7rem",
                            }}
                          >
                            {" "}
                            Add new template
                          </p>
                          <p style={{}}>Template Name (Required)</p>
                          <input
                            placeholder="Template name"
                            style={{
                              // display: showSubjectLine ? "flex" : "none",
                              paddingInline: "20px",
                              // width: "100%",
                              paddingBlock: "10px",
                              borderRadius: "10px",
                              backgroundColor: "white",
                              color: "#000",
                              fontWeight: "bold",
                            }}
                            // disabled={isOld || loading}
                            value={templateName}
                            onChange={(e) => {
                              setTemplateName(e.target.value);
                            }}
                          />
                          <p style={{}}>Template Details (Required)</p>
                          <textarea
                            placeholder="Template Details"
                            style={{
                              // display: showSubjectLine ? "flex" : "none",
                              paddingInline: "20px",
                              // width: "100%",
                              paddingBlock: "10px",
                              borderRadius: "10px",
                              backgroundColor: "white",
                              color: "#000",
                              fontWeight: "bold",
                            }}
                            // disabled={isOld || loading}
                            value={template}
                            onChange={(e) => {
                              setTemplate(e.target.value);
                            }}
                          />

                          <Button
                            disableElevation={true}
                            variant="contained"
                            onClick={() =>
                              templateName.length > 0 &&
                              template.length > 0 &&
                              addTemplate()
                            }
                            style={{
                              backgroundColor:
                                templateName.length > 0 && template.length > 0
                                  ? "#ffc000"
                                  : "gray",
                              color: "#fff",
                              marginTop: "40px",
                              borderRadius: "8px",
                            }}
                          >
                            + ADD
                          </Button>
                        </div>
                      )}
                    </Popup>
                  </div>
                )}
              </div>
              }
            </div>
          </div>
          {activeSidebar !== "lookup" && activeSidebar !== "confirmed" && (
            <div className="row padding">
              <Pagination
                count={Math.ceil(documents.length / K.PAGE_COUNT)}
                shape="rounded"
                className={classes.root}
                page={activePage}
                onChange={(_, page) => setActivePage(page)}
              />
            </div>
          )}
          <br />
        </div>
      )}
    </div>
  );
};
export default NavigationBar;
