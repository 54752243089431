import React from 'react';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import logo from '../assets/images/mochi-logo.png';
import { Image } from 'semantic-ui-react';
import MochiTextField from '../components/text-field';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { BeatLoader } from 'react-spinners';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import api from '../network/api';
import { User } from '../@types';
import setAuthToken from '../network/set-auth-token';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { loginUser } from '../store/modules/auth.reducer';
import { RootState } from '../store/root';
import K from '../constants';

const Copyright: React.FC = () => {
	return (
		<Typography variant="body2" color="textSecondary" align="center">
			{'Copyright © '}
			<Link
				color="inherit"
				href="https://learnwithmochi.com/"
				target="_blank"
				rel="noopener noreferrer"
			>
				Learn With Mochi
			</Link>{' '}
			{new Date().getFullYear()}
			{'.'}
		</Typography>
	);
};

const useStyles = makeStyles((theme) => ({
	paper: {
		marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.secondary.main,
	},
	form: {
		width: '100%',
		marginTop: theme.spacing(1),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
}));

const SignIn: React.FC = () => {
	const classes = useStyles();
	const [loading, setLoading] = React.useState(false);
	const dispatch = useDispatch();
	const auth = useSelector((state: RootState) => state.auth);
	const history = useHistory();

	React.useEffect(() => {
		if (auth.authenticated) {
			history.push('/');
		}
	}, [auth, history]);

	const { handleBlur, handleChange, handleSubmit, values, errors } = useFormik<{
		email: string;
		password: string;
	}>({
		initialValues: {
			email: '',
			password: '',
		},
		onSubmit: async (values) => {
			setLoading(true);
			try {
				const { data } = await api.post<{ token: string; user: User }>('/admin/login', values);

				// save token to local storage and to api headers
				window.localStorage.setItem(K.LWM_ADMIN_TOKEN_KEY, data.token);
				setAuthToken(data.token);
				dispatch(loginUser(data.user));

				toast.success('Logged in successfully');
				setLoading(false);
			} catch (error) {
				toast.error(
					 'Unable to log in at this time, try again later'
				);
				setLoading(false);
			}
		},
		validationSchema: Yup.object({
			email: Yup.string().email().required(),
			password: Yup.string().min(8).max(32).required(),
		}),
	});

	return (
		<Container component="main" maxWidth="xs">
			<Helmet title="Login | Mochi Admin" />
			<div className={classes.paper}>
				<Image className="logo" src={logo} />
				<Typography component="h1" variant="h4">
					Sign in
				</Typography>

				<br />
				<form className={classes.form} onSubmit={handleSubmit}>
					<MochiTextField
						id="email"
						name="email"
						className="input center"
						type="text"
						placeholder="Enter email"
						value={values.email}
						handleBlur={handleBlur}
						handleChange={handleChange}
						error={errors.email}
					/>

					<MochiTextField
						id="password"
						name="password"
						className="input center"
						type="password"
						placeholder="Enter password"
						value={values.password}
						handleChange={handleChange}
						handleBlur={handleBlur}
						error={errors.password}
						disabled={loading}
					/>
					<br />
					<Button
						disableElevation={true}
						type="submit"
						fullWidth
						variant="contained"
						style={{ backgroundColor: '#ffc000' }}
						className={classes.submit}
						disabled={loading}
					>
						{loading ? <BeatLoader /> : 'Sign In'}
					</Button>
				</form>
			</div>
			<Box mt={8}>
				<Copyright />
			</Box>
		</Container>
	);
};

export default SignIn;
