/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import styled from "styled-components";
import { Customer, Influencer } from "../../@types";
import api from "../../network/api";
import { setActiveSidebar } from "../../store/modules/misc.reducer";
import CircularLoading from "../Animations/Loading";
import InfluencerDashboardTable from "../InfluencerDashboardTable";


type Props = {
  influencers: Influencer[],
  originalInfluencers: Influencer[],
  loading: boolean
}

function Selection({influencers, originalInfluencers, loading}: Props){
  

  const dispatch = useDispatch();

  const gotoLookup = () => {
    dispatch(setActiveSidebar("lookup"));
  };

  
  

  return (
    <StyledDiv>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          position: "absolute",
          alignItems: "center",
          width: "80vw",
          flexDirection: "column",
          opacity: loading ? "1" : "0",
          transition: "all 0.3s ease",
          transform: loading ? "translateY(30vh)" : "translateY(20vh)",
        }}
      >
        <CircularLoading />
      </div>
      {influencers.length ? (
        <div
          style={{
            opacity: loading ? "0" : "1",
            transition: "all 0.3s ease",
            transform: loading ? "translateY(90px)" : "translateY(0px)",
          }}
        >
          <InfluencerDashboardTable
            influencers={influencers}
            loading={loading}
          />
        </div>
      ) : (
        <div style={{ opacity: loading ? "0" : "1" }} className="no-accounts">
          <h2>No accounts selected yet</h2>
          <button onClick={gotoLookup}>Add Accounts</button>
        </div>
      )}
    </StyledDiv>
  );
};

const StyledDiv = styled.div`
  margin-top: -5rem;
  width: 100%;
  & > .accounts {
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  & > .no-accounts {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 39vh;
    & > button {
      background: #ffcc00;
      color: #000;
      border: none;
      padding: 1rem 2rem;
      border-radius: 4px;
      cursor: pointer;
      font-weight: bold;
    }
  }
`;
// const LoadDiv = styled.div`
//   margin-top: 8rem;
//   height: 80vh;
//   display: flex;
//   align-self: center;
//   justify-content: center;
// `;

export default Selection;
