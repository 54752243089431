import React from 'react';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import Edit from '@material-ui/icons/Edit';
import Delete from '@material-ui/icons/Delete';
import Divider from '@material-ui/core/Divider';
import { toast } from 'react-toastify';
import api from '../network/api';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
        },
        paper: {
            marginRight: theme.spacing(2),
        },
    })
);

interface Props {
    customerId?: number;
    cancelled?: boolean;
}

function SubscriptionActions({ customerId, cancelled }: Props) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef<HTMLButtonElement>(null);
    const history = useHistory();

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event: React.MouseEvent<EventTarget>) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }

        setOpen(false);
    };

    function handleListKeyDown(event: React.KeyboardEvent) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        }
    }

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current!.focus();
        }

        prevOpen.current = open;
    }, [open]);

    const deleteCustomer = async () => {
        if (window.confirm("Are you sure you want to cancel this customer's subscription?")) {
            try {
                await api.delete(`/customers/${customerId}`);
                toast.success("Cancelled customer's subscription successfully");
                history.replace('/');
            } catch (error) {
                toast.error(
                    "Error cancelling customer's subscription"
                );
            }
        }
    };

    const editCustomer = async () => {
        history.push(`/${customerId}/edit`);
    };

    return (
        <div className={classes.root}>
            <div>
                <Button
                    style={{ backgroundColor: '#5469D4', color: '#fff', borderRadius: '8px' }}
                    ref={anchorRef}
                    aria-controls={open ? 'menu-list-grow' : undefined}
                    aria-haspopup="true"
                    onClick={handleToggle}
                >
                    ACTIONS
                    <KeyboardArrowDown />
                </Button>
                <Popper
                    open={open}
                    anchorEl={anchorRef.current}
                    role={undefined}
                    transition
                    disablePortal
                >
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{
                                transformOrigin:
                                    placement === 'bottom' ? 'center top' : 'center bottom',
                            }}
                        >
                            <Paper>
                                <ClickAwayListener onClickAway={handleClose}>
                                    <MenuList
                                        autoFocusItem={open}
                                        id="menu-list-grow"
                                        onKeyDown={handleListKeyDown}
                                    >
                                        <MenuItem
                                            onClick={(e) => {
                                                handleClose(e);
                                                editCustomer();
                                            }}
                                        >
                                            <div
                                                className="row"
                                                style={{ paddingTop: '10px', paddingBottom: '5px' }}
                                            >
                                                <Edit />
                                                <div className="padding">Edit Customer</div>
                                            </div>
                                        </MenuItem>
                                        <Divider variant="middle" />
                                        {!cancelled && (
                                            <MenuItem
                                                onClick={(e) => {
                                                    handleClose(e);
                                                    deleteCustomer();
                                                }}
                                            >
                                                <div
                                                    className="row"
                                                    style={{
                                                        color: '#f00',
                                                        paddingTop: '10px',
                                                        paddingBottom: '5px',
                                                    }}
                                                >
                                                    <Delete />
                                                    <div className="padding">
                                                        Cancel Subscription
                                                    </div>
                                                </div>
                                            </MenuItem>
                                        )}
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </div>
        </div>
    );
}

export default SubscriptionActions;
