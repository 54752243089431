/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Paper from "@material-ui/core/Paper";
import { Customer, Email, Influencer } from "../../@types";
import { format } from "date-fns";
import { useHistory } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import styled from "styled-components";
import UsersIcon from "../SVGIcons/UsersIcon";
import api from "../../network/api";
import { toast } from "react-toastify";

interface HeadCell {
  disablePadding: boolean;
  id: keyof Customer;
  label: string;
  numeric: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      marginTop: "2rem",
      background: "#000",
    },
    paper: {
      width: "100%",
      marginBottom: theme.spacing(2),
      background: "#000",
    },
    table: {
      width: "100%",
      background: "#000",
      color: "#fff",
      borderSpacing: "0 0px",
      borderCollapse: "separate",
      borderRadius: "15px",
      overflow: "hidden",
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
    tableRow: {
      background: "#E9F4F9",
      transition: "all 0.2s all",
      opacity: "0.95",
      "&:hover": {
        cursor: "pointer",
        background: "#E9F4F9",
        opacity: "1",
      },
    },
    tableRowNew: {
      background: "#ffcc00",
      transition: "all 0.2s all",
      opacity: "0.95",
      "&:hover": {
        cursor: "pointer",
        background: "#ffcc00",
        opacity: "1",
      },
    },
    cell: {
      color: "#000",
      fontWeight: "bold",
    },
    headerCell: {
      color: "#000",
      fontWeight: "bold",
      "&:hover": {
        cursor: "pointer",
      },
    },
  })
);

interface Props {
  hideHeader?: boolean;
  emailcount?: number;
  emailReplied?: boolean;
}

const CorrespondenceTable: React.FC<Props> = ({ hideHeader, emailcount }) => {
  const classes = useStyles();
  const history = useHistory();

  let [emails, setEmails] = useState<Email[]>([]);
  let [loading, setLoading] = useState(true);

  const onUserSelect = (customerId: number, email: string) => {
    api.post("/influencer/read", {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`,
      },
      email: email,
      is_read: true,
    });
    api.post("/influencer/new_email", {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`,
      },
      email: email,
      new_email: false,
    });
    history.push(`/influencer/${email}`);
  };

  useEffect(() => {
    const fetchEmails = async () => {
      try {
        const { data: emails } = await api.get("/messages/history",{  headers: {
          Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`,
        },});
        console.log(emails);
        setEmails(emails);
        setLoading(false);
        // alert(emails.length)
      } catch (error) {
        console.log(error);
        setLoading(false);
        toast.error("Could not load emails");
      }
    };

    fetchEmails();
  }, []);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer>
          {loading ? (
            <LoadDiv>
              <BeatLoader color="#ffcc00" />
            </LoadDiv>
          ) : (
            <>
              {emails.filter((email) => !email.email.includes("me:")).length === 0 ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    fontSize: "1.5rem",
                    color: "white",
                  }}
                >
                  No Influencer on this list
                </div>
              ) : (
                <div>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <h3 style={{ color: "white", marginLeft: "20px" }}>
                      All Correspondence :{" "}
                    </h3>
                    <h3 style={{ color: "#ffcc00", marginLeft: "10px" }}>
                      {" "}
                      {emails.filter((email) => !email.email.includes("me:")).length}
                    </h3>
                  </div>

                  <Table
                    className={classes.table}
                    aria-labelledby="tableTitle"
                    size="medium"
                    aria-label="enhanced table"
                  >
                    <TableBody>
                      {emails.filter((email) => !email.email.includes("me:")).map((influencer) => (
                        <TableRow
                          key={influencer.id}
                          aria-checked={true}
                          onClick={() =>
                            onUserSelect(influencer.id, influencer.email)
                          }
                          selected={false}
                          className={classes.tableRow}
                        >
                          <TableCell align="left" className={classes.cell}>
                            {influencer.email}
                          </TableCell>
                          <TableCell align="left" className={classes.cell}>
                          {influencer.subject} - <span style={{fontWeight:"normal"}}>{influencer.text.toString().substring(0,90)}</span>
                          </TableCell>


                          <TableCell align="left" className={classes.cell}>
                            {influencer.created_at
                              ? format(
                                  new Date(influencer.created_at),
                                  "dd-MM-yyyy"
                                )
                              : "-----"}
                          </TableCell>

                          <TableCell align="left" className={classes.cell}>
                              <div
                                style={{
                                  paddingInline: "10px",
                                  paddingBlock: "5px",
                                  backgroundColor: "#ffcc00",

                                  borderRadius: "10px",
                                  display: !influencer.is_read
                                    ? "flex"
                                    : "none",
                                  width: "50px",
                                  justifyContent: "center",
                                }}
                              >
                                New
                              </div>
                            </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </div>
              )}
            </>
          )}
        </TableContainer>
      </Paper>
    </div>
  );
};

const LoadDiv = styled.div`
  height: 50vh;
  width: 100%;
  margin-top: "400px";
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default CorrespondenceTable;
