import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import Button from '@material-ui/core/Button';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import FileCopy from '@material-ui/icons/FileCopy';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import { Subscription } from '../@types';

const useStyles = makeStyles({
	table: {
		minWidth: 650,
	},
});

interface Props {
	subscriptions: Subscription[];
}

const SubscriptionPricingTable: React.FC<Props> = ({ subscriptions }) => {
	const classes = useStyles();

	return (
		<TableContainer elevation={0} component={Paper}>
			<Table className={classes.table} aria-label="simple table">
				<TableHead>
					<TableRow>
						<TableCell>
							<div className="row">
								PRODUCT
								<KeyboardArrowDown />
							</div>
						</TableCell>
						<TableCell align="center">
							<div className="row">
								SUBSCRIPTION ITEM ID <KeyboardArrowDown />
							</div>
						</TableCell>
						<TableCell align="center">
							<div className="row">
								QTY <KeyboardArrowDown />
							</div>
						</TableCell>

						<TableCell align="center">
							<div className="row">
								TOTAL <KeyboardArrowDown />
							</div>
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{subscriptions.map((subscription) => (
						<TableRow key={subscription.id}>
							<TableCell component="th" scope="row">
								<div className="column">
									<div style={{ fontWeight: 'bold' }}>
										{`Subscription box - ${subscription.priceId}`}
									</div>
									<div
										style={{ color: 'grey', paddingTop: '5px', fontWeight: 'bold' }}
									>{`Every ${subscription.duration} at ${subscription.price}`}</div>
								</div>
							</TableCell>
							<TableCell align="left">
								<div className="row">
									<div
										style={{
											paddingRight: '60px',
											backgroundColor: '#F7FAFC',
											padding: '7px',
											border: '2px solid #E5E7EA',
											borderRadius: '5px',
										}}
									>
										{subscription.id}
									</div>
									<Button
										style={{
											color: '#fff',
											padding: '0px',
											margin: '0px',
										}}
									>
										<div
											style={{
												paddingLeft: '10px',
												paddingTop: '2px',
												backgroundColor: '#fff',
												padding: '7px',
												border: '2px solid #E5E7EA',
												borderRadius: '5px',
											}}
										>
											<FileCopy
												style={{
													color: 'grey',
													fontSize: '1em',
												}}
											/>
										</div>
									</Button>
								</div>
							</TableCell>
							<TableCell align="left">
								<div
									style={{
										fontWeight: 'bold',
									}}
								>
									{subscription.quantity}
								</div>
							</TableCell>
							<TableCell align="left">
								<div
									style={{
										fontWeight: 'bold',
									}}
								>
									{`Every ${subscription.duration} at ${subscription.price}`}
								</div>
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
};

export default SubscriptionPricingTable;
